import Link from 'next/link';
import React from 'react';

import { Grid, Section } from 'site-react/components/page';
import { Heading, TextWithIcon } from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';

import GuideItem from './GuideItem';
import styles from './ResourcesMenuContent.module.css';

const ResourcesMenuContent = () => {
  return (
    <Section verticalPadding="lg">
      <Grid>
        <Grid.Item colSpan="6" rowSpan="2">
          <div className={styles['ResourcesMenuContent-ruler']}>
            <Heading level="3" type="title4">
              Help Guides
            </Heading>
          </div>

          <VerticalSpacing size="md" />
          <UnstyledList>
            <GuideItem
              href="/blog/everything-about-renting-private-day-offices"
              imageAlt="Renting private day offices"
              imagePath="/site/navigation/help-guides/everything-about-flexible-office-space.jpeg"
              label="Everything You Need to Know About Renting Private Day Offices"
            />
            <GuideItem
              href="/blog/hybrid-working-ultimate-guide"
              imageAlt="Hybrid working 101"
              imagePath="/site/navigation/help-guides/hybrid101.png"
              label="Hybrid Working 101: The Ultimate Guide to Blended Working"
            />
            <GuideItem
              href="/blog/everything-about-flexible-office-space"
              imageAlt="Flexible office space"
              imagePath="/site/navigation/help-guides/renting-private-offices.png"
              label="Everything You Need to Know About Flexible Office Space"
            />

            <VerticalSpacing size="lg" />
            <Link href="/blog/moving-office">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text="More help guides"
              />
            </Link>
          </UnstyledList>
        </Grid.Item>
        <Grid.Item colSpan="6">
          <div className={styles['ResourcesMenuContent-ruler']}>
            <Heading level="3" type="title4">
              Learn More
            </Heading>
          </div>
          <VerticalSpacing size="md" />
          <UnstyledList className={styles['ResourcesMenuContent-linkList']}>
            <li>
              <Link href="/guides/hot-desk">Hot desking</Link>
            </li>
            <li>
              <Link href="/guides/coworking">Coworking</Link>
            </li>
            <li>
              <Link href="/blog/office-space-calculator">
                Calculate my Budget
              </Link>
            </li>
            <li>
              <Link href="/guides/leased-offices">Leasing</Link>
            </li>
            <li>
              <Link href="/guides/serviced-offices">Serviced Offices</Link>
            </li>
          </UnstyledList>
        </Grid.Item>
        <Grid.Item colSpan="6">
          <div className={styles['ResourcesMenuContent-ruler']}>
            <Heading level="3" type="title4">
              Hubble
            </Heading>
          </div>
          <VerticalSpacing size="md" />
          <UnstyledList className={styles['ResourcesMenuContent-linkList']}>
            <li>
              <Link href="/about">About Us</Link>
            </li>
            <li>
              <Link href="/contact">Contact</Link>
            </li>
            <li>
              <Link href="/blog">Blog</Link>
            </li>
          </UnstyledList>
        </Grid.Item>
      </Grid>
    </Section>
  );
};

export default ResourcesMenuContent;
