import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './NavBarCustomItem.module.css';

export default function NavBarCustomItem({ className, children, label }) {
  return (
    <li aria-label={label} className={cn(styles.NavBarCustomItem, className)}>
      {children}
    </li>
  );
}

NavBarCustomItem.propTypes = {
  /**
   * Children to render inside the nav item
   */
  children: PropTypes.node,

  /**
   * Optional extra class to assign to the <li> element
   */
  className: PropTypes.string,

  /**
   * Label to show on the nav item
   */
  label: PropTypes.string.isRequired,
};
