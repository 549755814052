import React from 'react';

import { ListItemWithIcon } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './ShortlistContent.module.css';

const ShortlistContent = () => {
  return (
    <aside className={styles['ShortlistContent-contentWrapper']}>
      <VerticalSpacing size="sm" />
      <ul className={styles['ShortlistContent-list']}>
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Easily save and compare multiple options in one place"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Share your shortlisted offices with other members of your team"
        />
      </ul>
      <VerticalSpacing size="sm" />
      <img
        alt="Trustpilot rating of 4.8 stars"
        className={styles['ShortlistContent-trustpilot']}
        src="https://hubble.imgix.net/site/homepage/trustpilot-black-letters.svg?auto=compress"
      />
    </aside>
  );
};

export default ShortlistContent;
