import cn from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { DayPicker } from 'react-day-picker';

import { ErrorMessage } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './DatePicker.module.css';
import TextInput from '../TextInput';
import disabledPropTypes from './proptypes/disabled.proptypes';

const DatePicker = React.forwardRef(
  (
    {
      disabled = [],
      errorText = null,
      labelText = null,
      onDateSelect = () => {},
      placeholder = null,
      selectedDate,
      status = null,
      testId,
    },
    ref,
  ) => {
    const [selected, setSelected] = React.useState(selectedDate);
    const [inputValue, setInputValue] = React.useState(() =>
      selectedDate
        ? DateTime.fromJSDate(selectedDate).toFormat('dd/MM/yyyy')
        : '',
    );
    const [isOpen, setIsOpen] = React.useState(false);

    const inputRef = React.useRef(null);

    const handleDateSelect = (date) => {
      setSelected(date);
      if (date) {
        const formattedDate = DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
        setInputValue(formattedDate);
        setIsOpen(false);
      } else {
        setInputValue('');
      }
      onDateSelect(date);
      setIsOpen(false);
    };

    const handleDateChange = (event) => {
      setInputValue(event.currentTarget.value);
      const date = DateTime.fromFormat(
        event.currentTarget.value,
        'dd/MM/yyyy',
      ).toJSDate();

      onDateSelect(date);

      if (date.toString() !== 'Invalid Date') {
        /**
         * Only setSelected if the date is valid otherwise it becomes impossible
         * to open the calendar UI onFocus.
         */
        setSelected(date);
      }
    };

    React.useEffect(() => {
      if (isOpen) {
        inputRef.current.focus();
      }
    }, [isOpen]);

    const divRef = React.useRef(null);

    return (
      <div
        onBlur={(event) => {
          if (
            !divRef.current.contains(event.relatedTarget) &&
            !inputRef.current.contains(event.relatedTarget)
          ) {
            setIsOpen(false);
          }
        }}
        onFocus={() => {
          if (!isOpen) {
            setIsOpen(true);
          }
        }}
        ref={divRef}
      >
        <TextInput
          aria-label={labelText || 'Date'}
          autoFocus={isOpen}
          iconType="calendar_today"
          key="date-picker-input"
          labelText={labelText}
          onChange={handleDateChange}
          onClick={(event) => {
            event.preventDefault();
            setIsOpen(true);
          }}
          placeholder={placeholder}
          ref={inputRef}
          type="text"
          value={inputValue}
        />
        {status === 'error' && errorText && (
          <>
            <VerticalSpacing size="sm" />

            <ErrorMessage isMarginless scrollIntoView>
              {errorText}
            </ErrorMessage>
          </>
        )}
        {isOpen && (
          <div
            aria-hidden={!isOpen}
            className={cn(styles.DatePicker, {
              [styles['is-open']]: isOpen,
            })}
            tabIndex="-1"
          >
            <DayPicker
              className={styles['DatePicker-dayPicker']}
              classNames={{
                chevron: styles['DatePicker-chevron'],
                day: styles['DatePicker-day'],
                month_caption: styles['DatePicker-monthCaption'],
                nav: styles['DatePicker-nav'],
                weekday: styles['DatePicker-weekday'],
              }}
              data-testid={testId}
              defaultMonth={selected}
              disabled={disabled}
              initialFocus={false}
              mode="single"
              modifiersClassNames={{
                disabled: styles['DatePicker-day--disabled'],
                selected: styles['DatePicker-day--selected'],
                today: styles['DatePicker-day--today'],
              }}
              onSelect={handleDateSelect}
              ref={ref}
              selected={selected}
            />
          </div>
        )}
      </div>
    );
  },
);

DatePicker.propTypes = {
  /**
   * An array of dates we want to be disabled. See https://react-day-picker.js.org/basics/modifiers#disabling-days for more info.
   */
  disabled: disabledPropTypes,

  /**
   * The label for the input
   */
  labelText: PropTypes.string,

  /**
   * The on date select function. Returns the selected date as a Date object. If the date entered is invalid this will still be passed
   * to the parent. It is the reponsibility of the parent to handle invalid dates.
   */
  onDateSelect: PropTypes.func,

  /**
   * The placeholder for the input
   */
  placeholder: PropTypes.string,

  /**
   * The selected date. This component assumes that this date is a valid date time. It is the responsibility
   * of the parent component to handle invalid dates and ensure they are not passed to this component.
   */
  selectedDate: PropTypes.instanceOf(Date),

  /**
   * The status of the input
   */
  status: PropTypes.oneOf(['error', null]),

  /**
   * The test id for the input
   */
  testId: PropTypes.string,
};

export default DatePicker;
