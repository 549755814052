import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

export default function Portal({ children, selector = 'body' }) {
  const [element, setElement] = React.useState(null);

  React.useEffect(() => {
    setElement(document.querySelector(selector));
  }, [selector]);

  if (!element) {
    return null;
  }

  return ReactDOM.createPortal(children, element);
}

Portal.propTypes = {
  children: PropTypes.node,
  selector: PropTypes.string,
};
