import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './ImageCarouselMoveButton.module.css';

const ImageCarouselMoveButton = ({
  direction,
  disabled = false,
  isExpandedView = false,
  onClick = () => {},
  smallArrowButtons = false,
}) => {
  const iconType = direction === 'next' ? 'navigate_next' : 'navigate_before';

  return (
    <span
      /* eslint-disable sort-keys */
      className={cn(styles['ImageCarouselMoveButton-buttonWrapper'], {
        [styles['ImageCarouselMoveButton-nextButtonWrapper']]:
          direction === 'next',
        [styles['ImageCarouselMoveButton-nextButtonWrapper--small']]:
          direction === 'next' && smallArrowButtons,
        [styles['ImageCarouselMoveButton-previousButtonWrapper']]:
          direction === 'previous',
        [styles['ImageCarouselMoveButton-previousButtonWrapper--small']]:
          direction === 'previous' && smallArrowButtons,
      })}
      /* eslint-enable sort-keys */
    >
      <button
        aria-label={`${direction} image`}
        className={cn(styles['ImageCarouselMoveButton-baseButton'], {
          [styles['ImageCarouselMoveButton-button']]: !isExpandedView,
          [styles['ImageCarouselMoveButton-invertedButton']]: isExpandedView,
          [styles['ImageCarouselMoveButton-button--small']]: smallArrowButtons,
        })}
        disabled={disabled}
        onClick={onClick}
        type="button"
      >
        <MaterialIcon
          className={styles['ImageCarouselMoveButton-icon']}
          iconType={iconType}
          style={{
            '--ImageCarouselMoveButton-iconPositionAddOn':
              iconType === 'navigate_before' ? '6px' : '5px',
          }}
        />
      </button>
    </span>
  );
};

ImageCarouselMoveButton.propTypes = {
  /**
   * The direction of this button.
   */
  direction: PropTypes.oneOf(['next', 'previous']).isRequired,

  /**
   * Is this button disabled?
   */
  disabled: PropTypes.bool,

  /**
   * Is the carousel expanded?
   */
  isExpandedView: PropTypes.bool,

  /**
   * Callback when the button activates.
   */
  onClick: PropTypes.func,

  /**
   * Is this button in the small style?
   */
  smallArrowButtons: PropTypes.bool,
};

export default ImageCarouselMoveButton;
