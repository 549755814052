import PropTypes from 'prop-types';
import React from 'react';

import styles from './FloatingNavigationAnchor.module.css';

const FloatingNavigationAnchor = ({ id }) => (
  <span className={styles.styles} id={id} />
);

FloatingNavigationAnchor.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FloatingNavigationAnchor;
