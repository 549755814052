import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';

import { Button } from 'site-react/components/form';
import { LinkAsButton } from 'site-react/components/navigation';
import { LazyModal } from 'site-react/components/page/ModalNew';
import { TextWithIcon } from 'site-react/components/typography';
import { OfficeAccessOptions } from 'site-react/data/listing/ViewingRequestContext';

import styles from './OfficeCardButtonGroup.module.css';

const OfficeCardButtonGroup = ({
  buildingName,
  cohort = 'control',
  location,
  officeAccess,
  pricePlanId,
  pricePlanSize,
  price,
  userEmail,
  uspContent = null,
}) => {
  const pathname = usePathname();

  const buttonProps = {
    analyticsMetadata: {
      label: 'Book a viewing (Office Card)',
      location: 'office-card',
      pricePlanId,
    },
    'aria-label': 'Book a viewing',
    isBlock: true,
    isCompact: true,
    name: 'Book a viewing',
    styleAtSmall: 'auto',
    styleType: 'primary',
  };

  const quoteModalButtonProps = {
    copy: cohort === 'control' ? 'Get a quote' : 'Enquire about this office',
    dataTestId: cohort === 'control' ? 'get-a-quote-button' : 'enquire-button',
    name: cohort === 'control' ? 'Get a quote' : 'Enquire about this office',
  };

  const quoteModalProps =
    officeAccess === OfficeAccessOptions.PartTime
      ? {
          buildingName: buildingName,
          location: location,
          pricePlanId: pricePlanId,
          pricePlanSize: pricePlanSize,
          userEmail: userEmail,
        }
      : {
          buildingName: buildingName,
          location: location,
          pricePlanId: pricePlanId,
          pricePlanPrice: price,
          pricePlanSize: pricePlanSize,
          userEmail: userEmail,
        };

  return (
    <div className={styles['button-group']}>
      <div className={styles['button-wrapper']}>
        <LinkAsButton
          {...buttonProps}
          href={{
            pathname: `${pathname}/book-viewing`,
            query: {
              access: officeAccess,
              option: pricePlanId,
            },
          }}
        >
          <TextWithIcon
            contentType="content2"
            iconName="bolt"
            iconPosition="left"
            iconSize="md"
            labelType="label1"
            text={buttonProps.name}
          />
        </LinkAsButton>
      </div>

      <div className={styles['link-wrapper']}>
        <LazyModal
          id="get-a-quote"
          label="Get a quote"
          lazyComponent={async () =>
            officeAccess === OfficeAccessOptions.PartTime
              ? (await import('../../../../../PartTimeQuoteModal')).default
              : (await import('../../../../../FullTimeQuoteModal')).default
          }
          renderTrigger={({ isLoading, openModal }) => (
            <Button
              data-analytics-tag="get-a-quote-office-details"
              data-testid={quoteModalButtonProps.dataTestId}
              disabled={isLoading}
              isBlock
              isCompact
              isLoading={isLoading}
              name={quoteModalButtonProps.name}
              onClick={openModal}
              styleType="secondary"
              type="button"
            >
              {quoteModalButtonProps.copy}
            </Button>
          )}
        >
          {(GetAQuoteModal) => <GetAQuoteModal {...quoteModalProps} />}
        </LazyModal>
      </div>
      {uspContent && <div className={styles['usp-wrapper']}>{uspContent}</div>}
    </div>
  );
};

OfficeCardButtonGroup.propTypes = {
  location: PropTypes.string.isRequired,
  officeAccess: PropTypes.oneOf(Object.values(OfficeAccessOptions)).isRequired,
  pricePlanId: PropTypes.number.isRequired,
  pricePlanSize: PropTypes.number.isRequired,
  uspContent: PropTypes.node,
};

export default OfficeCardButtonGroup;
