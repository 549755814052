export { ModalNewContext } from './context/ModalNewContext';
export { ModalNewTemplateBasic } from './templates/ModalNewTemplateBasic';
export {
  ModalNewTemplateWithSidebar,
  ModalNewTemplateWithSidebarSidebar,
  ModalNewTemplateWithSidebarContent,
} from './templates/ModalNewTemplateWithSidebar';

export { default as LazyModal } from './LazyModal';
export { default } from './ModalNew';
