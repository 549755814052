import PropTypes from 'prop-types';
import React from 'react';

import { CustomDropdown } from 'site-react/components/form';
import { MaterialIcon } from 'site-react/components/typography';

import styles from './DropdownProducts.module.css';

const DisplayComponent = ({ title, description }) => {
  return (
    <div className={styles['DropdownProducts-displayComponent']}>
      <span className={styles['DropdownProducts-displayContent']}>{title}</span>
      <span className={styles['DropdownProducts-capacity']}>{description}</span>
      <MaterialIcon
        className={styles['DropdownProducts-carat']}
        iconType="arrow_drop_down"
      />
    </div>
  );
};

const DropdownProducts = ({
  isDisabled,
  name,
  onChange,
  options,
  selectedId = '',
}) => {
  const selectedOption = options.find((option) => option.id === selectedId);

  return (
    <div className={styles['DropdownProducts-wrapper']}>
      <CustomDropdown
        disabled={isDisabled}
        displayComponent={
          <DisplayComponent
            description={selectedOption?.description ?? ''}
            title={selectedOption?.title ?? '-'}
          />
        }
        name={name}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={selectedId}
      >
        {options.length ? (
          options.map((option) => (
            <option key={option.id} value={option.id}>
              {option.title}
            </option>
          ))
        ) : (
          <option disabled>No products available</option>
        )}
      </CustomDropdown>
    </div>
  );
};

DropdownProducts.propTypes = {
  isDisabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.node.isRequired,
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ),
  selectedId: PropTypes.string.isRequired,
};

export default DropdownProducts;
