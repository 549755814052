import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { hotDesk, privateOffice } from 'site-react/assets';
import { RuledHeading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import { ProductPriceFormatter } from 'site-react/helpers/productPriceFormatter';
import usePermissions from 'site-react/hooks/usePermissions';
import usePriceType from 'site-react/hooks/usePriceType';

import styles from './PassProducts.module.css';
import getConvertedBookingResponseTime from '../../helpers/getConvertedBookingResponseTime';
import MeetingRoomProducts from '../MeetingRoomProducts';
import PassBookingContent from '../PassProducts/PassBookingContent';
import ProductBookingDetailsWrapper from '../PassProducts/ProductBookingDetailsWrapper';
import ProductOverviewWrapper from '../PassProducts/ProductOverviewWrapper';

const MAX_BOOKING_RESPONSE_HOURS = 3;

const Product = ({
  bookingDetailsHeading,
  bookingResponseTime,
  building,
  capacityText,
  cardTitle,
  description,
  imageSrc,
  isEnabled = true,
  priceText,
  product,
  showInstantBook = false,
}) => {
  return (
    <ProductOverviewWrapper
      description={description}
      imageSrc={imageSrc}
      title={cardTitle}
    >
      <ProductBookingDetailsWrapper
        bookingResponseTime={bookingResponseTime}
        heading={bookingDetailsHeading}
        showInstantBook={showInstantBook}
      >
        <PassBookingContent
          building={building}
          capacityText={capacityText}
          isBookingButtonEnabled={isEnabled}
          priceText={priceText}
          product={product}
        />
      </ProductBookingDetailsWrapper>
    </ProductOverviewWrapper>
  );
};

const PassProducts = ({ building, orderOfProducts }) => {
  const { canRequestPrivateOffice } = usePermissions();
  const priceType = usePriceType();

  const { products = [] } = building;

  const priceFormatter = new ProductPriceFormatter('en-GB', 'GBP');

  const coworkingProduct = useMemo(
    () => products.find((product) => product['productType'] === 'coworking'),
    [products],
  );
  const coworkingProductCapacityText =
    building.passCapacityLimit === 1
      ? '1 Person'
      : `1 - ${building.passCapacityLimit || 6} People`;

  const privateOfficeProduct = useMemo(
    () => products.find((product) => product.productType === 'private-office'),
    [products],
  );

  const meetingRoomProducts = useMemo(
    () => products.filter((product) => product.productType === 'meeting-room'),
    [products],
  );

  const meetingRoomBookingResponse = getConvertedBookingResponseTime(
    building?.bookingResponseStats?.meetingRoomAvgResponseTime,
    MAX_BOOKING_RESPONSE_HOURS,
  );
  const privateOfficeBookingResponse = getConvertedBookingResponseTime(
    building?.bookingResponseStats?.privateOfficeAvgResponseTime,
    MAX_BOOKING_RESPONSE_HOURS,
  );

  const coworkingProductPrice =
    coworkingProduct &&
    priceFormatter.getFormattedPrice(
      coworkingProduct[priceFormatter.priceTypeConfig[priceType]],
      priceType,
    );

  const privateOfficeProductPrice =
    privateOfficeProduct &&
    priceFormatter.getFormattedPrice(
      privateOfficeProduct[priceFormatter.priceTypeConfig[priceType]],
      priceType,
    );

  const productCards = {
    coworking: coworkingProduct && (
      <Product
        bookingDetailsHeading="Book a space"
        building={building}
        capacityText={coworkingProductCapacityText}
        cardTitle="Coworking Space"
        description="Access to shared workspace"
        imageSrc={hotDesk}
        priceText={`${coworkingProductPrice} / person / day`}
        product={coworkingProduct}
        showInstantBook={true}
      />
    ),
    'meeting-room': meetingRoomProducts.length > 0 && (
      <MeetingRoomProducts
        bookingResponseTime={meetingRoomBookingResponse}
        building={building}
        isInstantBookAvailable={building.isInstantBookAvailable}
        products={meetingRoomProducts}
      />
    ),
    'private-office': privateOfficeProduct && (
      <Product
        bookingDetailsHeading="Request to book"
        bookingResponseTime={privateOfficeBookingResponse}
        building={building}
        capacityText={`${privateOfficeProduct.minDeskCapacity} - ${privateOfficeProduct.maxDeskCapacity} People`}
        cardTitle="Private Day Office"
        description="A private room with desks and chairs"
        imageSrc={privateOffice}
        isEnabled={canRequestPrivateOffice}
        priceText={`${privateOfficeProductPrice} / person / day`}
        product={privateOfficeProduct}
      />
    ),
  };

  return (
    <>
      <RuledHeading level="2">On-demand workspaces</RuledHeading>
      <VerticalSpacing size="md" />
      {products.length === 0 ? (
        <div>No products available.</div>
      ) : (
        <ul
          aria-label="On—Demand Workspaces"
          className={styles['PassProducts-list']}
        >
          {orderOfProducts
            .filter((product) => productCards[product])
            .map((activeProduct, index) => {
              return (
                <li
                  aria-label={activeProduct}
                  className={styles['PassProducts-productItem']}
                  key={activeProduct}
                >
                  {productCards[activeProduct]}
                </li>
              );
            })}
        </ul>
      )}
    </>
  );
};

PassProducts.propTypes = {
  /**
   * Building
   */
  building: PropTypes.shape({
    extraFacilities: PropTypes.arrayOf(PropTypes.string),
    includedFacilities: PropTypes.arrayOf(PropTypes.string),
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default PassProducts;
