import { UICard } from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { Asset, VerticalSpacing } from 'site-react/components/utility';

import styles from './ProductOverviewWrapper.module.css';

const ProductOverviewWrapper = ({ title, description, imageSrc, children }) => {
  return (
    <UICard
      boxShadow="resting"
      style={{
        border: `1px solid var(--color-neutral-50)`,
        padding: `var(--space-md)`,
      }}
    >
      <div className={styles['ProductOverviewWrapper-content']}>
        <div className={styles['ProductOverviewWrapper-image']}>
          <Asset
            alt={`${title} illustration`}
            sizingBehaviour="fill"
            src={imageSrc}
          />
        </div>
        <div>
          <Heading level="3" type="title4">
            {title}
          </Heading>
          <VerticalSpacing size="sm" />
          <Paragraph isMarginless type="content3">
            {description}
          </Paragraph>
        </div>
      </div>
      {children}
    </UICard>
  );
};

export default ProductOverviewWrapper;
