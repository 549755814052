import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import { Heading } from 'site-react/components/typography';
import imgixLoader from 'site-react/helpers/imgixLoader';

import styles from './GuideItem.module.css';

export default function GuideItem({ href, imageAlt, imagePath, label }) {
  return (
    <li>
      <Link className={cn(styles['GuideItem-wrapper'])} href={href}>
        <Image
          alt={imageAlt}
          height={54}
          loader={imgixLoader}
          src={imagePath}
          width={54}
        />
        <div>
          <Heading type="content2">{label}</Heading>
        </div>
      </Link>
    </li>
  );
}
