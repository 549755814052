import NextLink from 'next/link';

import { Grid, Section } from 'site-react/components/page';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { UnstyledList, VerticalSpacing } from 'site-react/components/utility';

import FeaturedCard from './FeaturedCard';
import styles from './SolutionsMenuContent.module.css';

const SolutionsMenuContent = () => (
  <Section verticalPadding="lg">
    <Grid>
      <Grid.Item colSpan="2" rowSpan="1">
        <Heading level="4" type="title3">
          Office Space
        </Heading>
      </Grid.Item>
      <Grid.Item colSpan="3" rowStart="2">
        <UnstyledList>
          <li>
            <NextLink href="/part-time-offices">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Part-time offices</b>}
              />
              <VerticalSpacing size="sm" />
              <Paragraph contentType="content2">
                Your own office 1-3 days per week, up to 75% cheaper than a
                full-time office
              </Paragraph>
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="/full-time-office-enquiry?source=navBar">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Full-time offices</b>}
              />
              <VerticalSpacing size="sm" />
              <Paragraph contentType="content2">
                Permanent offices for your team with flexible contracts
              </Paragraph>
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
        </UnstyledList>
      </Grid.Item>
      <Grid.Item colSpan="2" colStart="5" rowSpan="1">
        <Heading level="4" type="title3">
          On-Demand
        </Heading>
      </Grid.Item>
      <Grid.Item colSpan="2" colStart="5" rowStart="2">
        <UnstyledList>
          <li>
            <NextLink href="/meeting-rooms/">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Meeting rooms</b>}
              />
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="/private-day-offices">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Day offices</b>}
              />
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="/coworking/london">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Coworking passes</b>}
              />
            </NextLink>
            <VerticalSpacing size="lg" />
          </li>
          <li>
            <NextLink href="https://search.hubblehq.com/events/">
              <TextWithIcon
                contentType="content1"
                iconName="chevron_right"
                iconSize="lg"
                text={<b>Event spaces</b>}
              />
              <VerticalSpacing size="sm" />
            </NextLink>
          </li>
        </UnstyledList>
      </Grid.Item>
      <Grid.Item colSpan="3" rowStart="2">
        <NextLink href="/pass">
          <TextWithIcon
            contentType="content1"
            iconName="chevron_right"
            iconSize="lg"
            text={<b>Monthly plans</b>}
          />
          <VerticalSpacing size="sm" />
          <Paragraph contentType="content2">
            Save up to 30% per booking with Hubble On-Demand monthly plans
          </Paragraph>
          <VerticalSpacing size="sm" />
        </NextLink>
      </Grid.Item>
      <Grid.Item colSpan="4" colStart="10" rowSpan="3" rowStart="1">
        <div
          className={styles['SolutionsMenuContent-featuredContentBackground']}
        >
          <div className={styles['SolutionsMenuContent-featuredContent']}>
            <Heading type="content3">
              <b>Featured</b>
            </Heading>
            <VerticalSpacing size="sm" />
            <UnstyledList>
              <li>
                <FeaturedCard
                  ctaContent="Get the guide"
                  description="The ultimate workspace strategy guide"
                  heading="Guide"
                  href="https://search.hubblehq.com/ultimate-guide-to-workspace-strategy/?utm_source=blog&utm_medium=referral"
                  imageAlt="Ultimate workspace strategy guide"
                  imageSrc="/site/navigation/solutions/workplace-strategy.png"
                  linkType="OutboundLink"
                />
                <VerticalSpacing size="lg" />
              </li>
              <li>
                <FeaturedCard
                  ctaContent="Read more"
                  description="The ultimate office cost calculator"
                  heading="Tools"
                  href="/blog/office-space-calculator"
                  imageAlt="Communal office space"
                  imageSrc="/site/navigation/solutions/calculator.jpg"
                />
              </li>
            </UnstyledList>
          </div>
        </div>
      </Grid.Item>
    </Grid>
  </Section>
);

export default SolutionsMenuContent;
