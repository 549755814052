import PropTypes from 'prop-types';

import { Grid, Section } from 'site-react/components/page';
import { VerticalSpacing } from 'site-react/components/utility';

import styles from './LayoutWrapper.module.css';

const LayoutWrapper = ({
  layoutStyle,
  isBookingInProgress = false,
  isSignUp,
  signupHeading,
  loginHeading,
  content,
  children,
}) => {
  const modalLayout = (
    <div className={styles['LayoutWrapper-modalWrapper']}>
      <div className={styles['LayoutWrapper-modalFormWrapper']}>
        <div className={styles['LayoutWrapper-showInSmallScreen']}>
          <VerticalSpacing size="sm" />
          {isSignUp ? signupHeading : loginHeading}
        </div>
        <VerticalSpacing size="sm" />
        {children}
      </div>

      <div className={styles['LayoutWrapper-modalContentWrapper']}>
        <div className={styles['LayoutWrapper-showInLargeScreen']}>
          {isSignUp ? signupHeading : loginHeading}
        </div>
        {content}
      </div>
    </div>
  );

  const pageLayout = (
    <Section verticalPadding="lg">
      <VerticalSpacing size="md" />
      <Grid>
        <Grid.Item colSpan="7">
          <div
            className={
              isBookingInProgress
                ? styles['LayoutWrapper-noShadow']
                : styles['LayoutWrapper-formWrapper']
            }
          >
            {children}
          </div>
        </Grid.Item>

        <div className={styles['LayoutWrapper-contentBreak']}>
          <VerticalSpacing size="sm" />
        </div>

        <Grid.Item colSpan="5">{content}</Grid.Item>
      </Grid>
    </Section>
  );

  return layoutStyle === 'modal' ? modalLayout : pageLayout;
};

LayoutWrapper.propTypes = {
  children: PropTypes.node,

  /**
   * Extra content to explain the purpose of logging in/signing up. Good to use for
   * added context when used as part of a user journey for a specific experience, such
   * as Pass.
   *
   * Can be used to, for example, explain that by logging in, you'll be joining
   * an organisation.
   */
  content: PropTypes.node,

  isBookingInProgress: PropTypes.bool,

  /**
   * Is this a sign up form?
   */
  isSignUp: PropTypes.bool,

  /**
   * Define the style for this component depending on where it's being implemented.
   *
   * Defaults to `page`
   */
  layoutStyle: PropTypes.oneOf(['page', 'modal']),

  /**
   * The signup heading component
   */
  loginHeading: PropTypes.node,

  /**
   * The signup heading component
   */
  signupHeading: PropTypes.node,
};

export default LayoutWrapper;
