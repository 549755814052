import PropTypes from 'prop-types';
import { useState } from 'react';

import ModalNew from '../ModalNew';

export default function LazyModal({
  children,
  lazyComponent,
  renderTrigger,
  ...modalProps
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [Component, setComponent] = useState(null);

  async function loadComponent() {
    const LoadedComponent = await lazyComponent();

    setComponent(() => LoadedComponent);
  }

  return (
    <ModalNew
      {...modalProps}
      renderTrigger={({ openModal }) => {
        return renderTrigger({
          isLoading,
          openModal: async () => {
            setIsLoading(true);

            await loadComponent();

            openModal();

            setIsLoading(false);
          },
        });
      }}
    >
      {Component ? children(Component) : null}
    </ModalNew>
  );
}

LazyModal.propTypes = {
  /**
   * A function which should return a component to be rendered in the modal.
   */
  children: PropTypes.func.isRequired,

  /**
   * A function which should return a promise that resolves to the component to be loaded
   */
  lazyComponent: PropTypes.func.isRequired,

  /**
   * A function which should handle opening the content of the modal.
   *
   * We pass back an object with the following properties:
   * - isLoading: A boolean indicating if the component is currently loading.
   * - openModal: A function which should be called to open the modal.
   */
  renderTrigger: PropTypes.func.isRequired,
};
