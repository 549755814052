import PropTypes from 'prop-types';
import React from 'react';

import { UnstyledButton } from 'site-react/components/form';
import { MaterialIcon } from 'site-react/components/typography';

import styles from './BottomAccessoryButton.module.css';

const BottomAccessoryButton = ({
  iconType = '',
  isEnabled = true,
  isLoading = false,
  enabledIconColor = 'neutral-900',
  label = '',
  onClick,
  testId,
  title = '',
}) => (
  <UnstyledButton
    aria-label={label}
    className={styles['BottomAccessoryButton-action']}
    isEnabled={isEnabled}
    label={label}
    onClick={onClick}
    testId={testId}
    title={title}
  >
    {isLoading ? (
      <div className={styles['BottomAccessoryButton-loading']} />
    ) : (
      <>
        <MaterialIcon
          className={styles['BottomAccessoryButton-icon']}
          iconType={iconType}
          style={{
            '--BottomAccessoryButton-iconColor': `var(--color-${enabledIconColor})`,
          }}
        />
        <span className={styles['BottomAccessoryButton-text']}>{label}</span>
      </>
    )}
  </UnstyledButton>
);

BottomAccessoryButton.propTypes = {
  /**
   * Color of the enabled icon.
   */
  enabledIconColor: PropTypes.string,

  /**
   * The Material Icon to display.
   *
   * Can be the name of any icon specified [here](https://material.io/tools/icons/).
   */
  iconType: PropTypes.string,

  /**
   * Is the user able to open this modal?
   */
  isEnabled: PropTypes.bool,

  /**
   * Label text that represents this button.
   * This will be the main prop sent to analytics; key of button
   */
  label: PropTypes.string.isRequired,

  /**
   * Callback when the "Request floor plan" button is activated
   */
  onClick: PropTypes.func.isRequired,

  /**
   * Optional string for the Unstyled Button to render in a `data-testid`
   *  attribute to allow element to be found in tests
   */
  testId: PropTypes.string,

  /**
   * The button's title. Note: this is used as the tooltip, not as the label.
   */
  title: PropTypes.string,
};

export default BottomAccessoryButton;
