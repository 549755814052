import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { UICard, UICardLink } from 'site-react/components/page';
import { Heading, Paragraph } from 'site-react/components/typography';
import { Asset } from 'site-react/components/utility';

import styles from './CardItem.module.css';

const CardItem = ({
  description,
  disabled,
  href,
  iconAlt,
  iconSrc,
  isActive = false,
  label,
  linkType,
  target,
}) => {
  const content = (
    <div
      className={cn(styles['CardItem-content'], {
        [styles['CardItem-contentStyleDisabled']]: disabled,
      })}
    >
      <Asset
        alt={iconAlt}
        className={styles['CardItem-icon']}
        height="48px"
        loading="lazy"
        sizingBehaviour="fixed"
        src={iconSrc}
        width="55px"
      />
      <div>
        <Heading level="4" type="title4">
          {label}
        </Heading>
        <Paragraph isMarginless type="content3">
          {description}
        </Paragraph>
      </div>
    </div>
  );

  if (disabled) {
    return (
      <li aria-label={label} className={styles['CardItem']}>
        <UICard
          backgroundColor="neutral50"
          boxShadow="subtle"
          data-testid={`card-item-${label}`}
          style={{
            border: isActive
              ? `2px solid var(--color-brandsecondary)`
              : `1px solid var(--color-neutral-100)`,
            padding: `var(--space-lg) var(--space-md)`,
          }}
        >
          {content}
        </UICard>
      </li>
    );
  }

  return (
    <li aria-label={label} className={styles['CardItem']}>
      <UICardLink
        analyticsMetadata={{
          label,
          location: 'navigation',
        }}
        aria-current={isActive}
        backgroundColor="white"
        boxShadow="subtle"
        data-testid={`card-item-${label}`}
        href={href}
        linkType={linkType}
        name={label}
        style={{
          border: isActive
            ? `2px solid var(--color-brandsecondary)`
            : `1px solid var(--color-neutral-100)`,
          padding: `var(--space-lg) var(--space-md)`,
        }}
        target={target}
        title={label}
      >
        {content}
      </UICardLink>
    </li>
  );
};

CardItem.propTypes = {
  /**
   * description text
   */
  description: PropTypes.string.isRequired,

  /**
   * href of link
   */
  href: PropTypes.string.isRequired,

  /**
   * icon alt text
   */
  iconAlt: PropTypes.string.isRequired,

  /**
   * icon image
   */
  iconSrc: PropTypes.string.isRequired,

  /**
   * Does this item represent the page the user is currently on?
   */
  isActive: PropTypes.bool,

  /**
   * Label, to present at the head of this component
   */
  label: PropTypes.string.isRequired,

  /**
   * linkType attribute. Only applies to for logic to decide element type.
   */
  linkType: PropTypes.oneOf(['a', 'OutboundLink']),

  /**
   * target attribute. Only applies to <a> and <OutboundLink> elements.
   */
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
};

export default CardItem;
