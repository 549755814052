import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';

import { Modal } from 'site-react/components/page';
import { Context as ShortlistContext } from 'site-react/data/core/ShortlistContext';
import {
  ViewingRequestContext,
  OfficeAccessOptions,
} from 'site-react/data/listing/ViewingRequestContext';
import DownloadPDFRequest from 'site-react/features/DownloadPDFRequest';
import ImageCarousel, {
  ImageCarouselItem,
  getSrcSet,
} from 'site-react/features/ImageCarousel';
import ShortlistButton from 'site-react/features/ShortlistButton';
import Signup, { ShortlistContent } from 'site-react/features/Signup';
import useFavouriteToggle from 'site-react/hooks/useFavouriteToggle';
import useFeatureFlags from 'site-react/hooks/useFeatureFlags';
import usePricePlan from 'site-react/hooks/usePricePlan';
import theme from 'site-react/theme';

import Tour360 from '../../components/Tour360';

/*
 * Sizes specify device-independent pixel sizes, so on a 2x screen, a size of
 * xl is equivalent to xl*2 actual pixels.
 *
 * Used for `<img />` `sizes` attribute.
 */
const imageWidths = [
  theme.breakpoints.sm,
  theme.breakpoints.md,
  theme.breakpoints.lg,
  theme.breakpoints.xl,
];

const ListingPageImageCarousel = ({
  building,
  hideAccessories,
  desktopRatio = '3:2', // Default aspect ratio value for desktop
  mobileRatio = '4:3', // Default aspect ratio value for  mobile
  overrideAspectRatio = false, // Currently this is only set for the hq listing page, the pass listing pages which also uses this component will be updated in due course.
}) => {
  const { enableImageCarouselCaptions } = useFeatureFlags();
  const { showSignupModal, setShowSignupModal, onSignupOrLogin } =
    useContext(ShortlistContext);
  const { pricePlan } = usePricePlan();
  const { officeAccess } = useContext(ViewingRequestContext);

  const isPartTime =
    officeAccess === OfficeAccessOptions.PartTime ? true : false;

  const shortlistId = pricePlan?.id;
  const { isShortlisted, isShortlistLoading, onShortlistClick } =
    useFavouriteToggle(shortlistId, isPartTime);

  const shortlistProps = useMemo(
    () => ({
      analyticsMetadata: {
        location: 'listing-page',
        pricePlanId: shortlistId,
      },
      isActivated: isShortlisted,
      isEnabled: shortlistId ? true : false,
      isLoading: isShortlistLoading,
      onClick: onShortlistClick,
    }),
    [isShortlistLoading, isShortlisted, onShortlistClick, shortlistId],
  );

  const carouselItems = building.images.map((item) => ({
    alt: item.caption,
    caption: item.caption,
    id: item.id,
    sizes: `(min-width: ${theme.breakpoints.md}px) 656px, 100vw`,
    src: item.img,
    srcSet: getSrcSet(item.img, imageWidths),
  }));

  const shouldHideAccessories =
    hideAccessories || building.status === 'archived';

  return (
    <>
      <ImageCarousel
        allowExpansion
        bottomAccessories={
          !shouldHideAccessories && (
            <>
              {building.vrTourUrl && <Tour360 tourUrl={building.vrTourUrl} />}

              <ShortlistButton
                {...shortlistProps}
                label="Save for later"
                variant="carousel"
              />
              <DownloadPDFRequest building={building} />
            </>
          )
        }
        desktopRatio={desktopRatio}
        enableImageCarouselCaptions={enableImageCarouselCaptions}
        isRounded
        mobileRatio={mobileRatio}
        overrideAspectRatio={overrideAspectRatio}
        trackingId={building.id}
      >
        {carouselItems.map((item) => (
          <ImageCarouselItem key={item.id} {...item} />
        ))}
      </ImageCarousel>
      {showSignupModal && !hideAccessories && (
        <Modal
          id="signup"
          isOpenOnRender
          modalName="Listing Image Carousel"
          onCloseModal={() => setShowSignupModal(false)}
          renderTrigger={() => {}}
        >
          <Signup
            isSignupByDefault
            layoutStyle="modal"
            onLogin={onSignupOrLogin}
            onSignup={onSignupOrLogin}
            signupTitle="Sign up to save, share and compare"
          >
            <ShortlistContent />
          </Signup>
        </Modal>
      )}
    </>
  );
};

ListingPageImageCarousel.propTypes = {
  /**
   * a Building object
   */
  building: PropTypes.shape({
    id: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    status: PropTypes.oneOf(['archived', 'published']),
    vrTourUrl: PropTypes.string,
  }).isRequired,
};

export default ListingPageImageCarousel;
