import cn from 'classnames';
import { default as NextLink } from 'next/link';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import Analytics from 'site-react/helpers/Analytics';

import styles from './LocationItem.module.css';

const LocationItem = ({ href, label }) => {
  const pathname = usePathname();
  const isCurrent = Boolean(pathname?.startsWith(href));

  const handleClick = useCallback(() => {
    Analytics.track(
      'Link clicked',
      {
        href,
        label,
        location: 'navigation',
      },
      {
        sendPageProperties: true,
      },
    );
  }, [href, label]);

  return (
    <li className={styles['LocationItem-item']}>
      <NextLink
        className={cn(styles['LocationItem-link'], {
          [styles['LocationItem-active']]: isCurrent,
        })}
        href={href}
        onClick={handleClick}
      >
        {label}
      </NextLink>
    </li>
  );
};

LocationItem.propTypes = {
  /**
   * href of link
   */
  href: PropTypes.string.isRequired,
  /**
   * Label, to present at the head of this component
   */
  label: PropTypes.string.isRequired,
};

export default LocationItem;
