import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef, useCallback } from 'react';

import analytics from 'site-react/helpers/Analytics';
import logError from 'site-react/helpers/logError';
import usePrevious from 'site-react/hooks/usePrevious';

import SearchAsIMoveToggle from './components/SearchAsIMoveToggle';
import polygonStyle from './helpers/polygonStyle';
import useGoogleMapsScript from './hooks/useGoogleMapsScript';
import useMap from './hooks/useMap';
import useMapApi from './hooks/useMapApi';
import useMapPosition from './hooks/useMapPosition';
import usePlotMarkers from './hooks/usePlotMarkers';
import styles from './Map.module.css';

const Map = ({
  favouriteIds = [],
  gestureHandling = 'greedy',
  highlightedId = null,
  isAreaSearch = false,
  isSearchable = false,
  initialMapSettings = {
    center: { lat: 51.509865, lng: -0.118092 },
    zoom: 14,
  },
  results = [],
  searchQuery,
  shape = {},
  submitQuery = () => {},
  children = () => null,
}) => {
  // This is the last pin a user clicked on
  const [activeId, setActiveId] = useState();
  // Turns out we always get a shape, it is ether a point or an area outline.
  // Area outlines can be one of “Polygon” and “MultiPolygon”, what happens
  // if you are searching and while dragging you may ask? You get London—I
  // think this is a bug but out of scope for this fix, although it did make
  // it harder.
  const zoomToAreaOutline = ['Polygon', 'MultiPolygon'].includes(
    shape?.geometry?.type,
  );
  // this controls the 'search as i move map' functionality
  const [toggleStatus, setToggleStatus] = useState(() =>
    isAreaSearch ? 'inactive' : 'active',
  );
  initialMapSettings.gestureHandling = gestureHandling;
  useEffect(() => {
    if (isAreaSearch) {
      setToggleStatus('inactive');
    }
  }, [isAreaSearch, zoomToAreaOutline]);

  // this indicates whether the google maps api has loaded
  const [isScriptReady, setIsScriptReady] = useState(false);

  // Using a ref here, to avoid a re-rendering loop. isFittingBounds is not
  // meant to affect rendering; it's meant to _prevent_ a render from occurring
  // when it shouldn't. State inherently triggers a re-render, so it would not
  // be appropriate here.
  const isFittingBoundsRef = useRef(false);
  const setIsFittingBounds = useCallback((newValue) => {
    isFittingBoundsRef.current = newValue;
  }, []);

  useGoogleMapsScript(setIsScriptReady);

  const { GoogleMap, mapRef } = useMapApi(isScriptReady);

  const element = mapRef.current;
  const { map, mapQuery, setMapQuery } = useMap({
    element,
    gestureHandling,
    GoogleMap,
    initialMapSettings,
    isFittingBoundsRef,
    isSearchable,
    setActiveId,
  });
  useEffect(() => {
    if (!map || !isAreaSearch) return;
    map.data.setStyle(polygonStyle);
    try {
      const features = map.data.addGeoJson(shape);
      return () => {
        features.forEach((feature) => map.data.remove(feature));
      };
    } catch (error) {
      logError(error);
    }
  }, [isAreaSearch, map, shape]);
  useMapPosition({
    GoogleMap,
    isAreaSearch,
    isSearchable,
    map,
    results,
    searchQuery,
    setIsFittingBounds,
    setToggleStatus,
    shape,
  });
  usePlotMarkers({
    activeId,
    favouriteIds,
    GoogleMap,
    highlightedId,
    map,
    results,
    setActiveId,
    setIsFittingBounds,
    setToggleStatus,
    zoomToAreaOutline,
  });

  const previousMapQuery = usePrevious(mapQuery);
  const previousToggleStatus = usePrevious(toggleStatus);

  useEffect(() => {
    if (isSearchable) {
      const mapQueryHasChanged = !isEqual(previousMapQuery, mapQuery);

      setToggleStatus((status) => {
        if (!results.length && status === 'active') {
          return 'no-results';
        } else if (results.length && status === 'no-results') {
          return 'active';
        } else if (
          mapQueryHasChanged &&
          (status === 'inactive' || previousToggleStatus === 'no-results')
        ) {
          return 'redo-search';
        } else {
          return status;
        }
      });
    }
  }, [
    isSearchable,
    mapQuery,
    previousMapQuery,
    previousToggleStatus,
    results,
    toggleStatus,
  ]);

  useEffect(() => {
    const mapQueryHasChanged = !isEqual(previousMapQuery, mapQuery);
    const toggleStatusHasChanged = !isEqual(previousToggleStatus, toggleStatus);

    /**
     * Avoid firing off an unnecessary search query if
     * neither the toggle status nor the map query have changed.
     *
     * This will help prevent unnecessary re-renders further up
     * the component chain.
     */
    const mapQueryOrToggleStatusHasChanged =
      mapQueryHasChanged || toggleStatusHasChanged;

    if (
      mapQuery &&
      mapQueryOrToggleStatusHasChanged &&
      toggleStatus !== 'inactive' &&
      toggleStatus !== 'redo-search'
    ) {
      submitQuery({ ...mapQuery });
      analytics.track(
        'Searched from Map',
        { query: mapQuery },
        { sendPageProperties: true },
      );
      // After we have fired the query, we want to reset the state of the map query to prevent re-renders.
      setMapQuery();
    }
  }, [
    mapQuery,
    previousMapQuery,
    previousToggleStatus,
    setMapQuery,
    submitQuery,
    toggleStatus,
  ]);

  return (
    <>
      {isSearchable && (
        <SearchAsIMoveToggle onToggle={setToggleStatus} status={toggleStatus} />
      )}
      <div className={styles['Map']} ref={mapRef} />
      {activeId && children({ buildingId: activeId })}
    </>
  );
};

Map.propTypes = {
  /**
   * An array of the current user's favourite listing ids.
   */
  favouriteIds: PropTypes.arrayOf(PropTypes.number),

  gestureHandling: PropTypes.oneOf(['greedy', 'cooperative', 'none']),
  /**
   * The id of the currently highlighted priceplan, if any.
   */
  highlightedId: PropTypes.number,

  /**
   * The initial map settings, minus the shape property, so we can pass that in
   * separately. See here for more info:
   * https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
   */
  initialMapSettings: PropTypes.shape({
    center: PropTypes.shape({ lat: PropTypes.number, lng: PropTypes.number }),
    zoom: PropTypes.number,
  }),

  /**
   * Is the map being searched for a specific named area, e.g. Shoreditch?
   */
  isAreaSearch: PropTypes.bool,

  /**
   * A boolean flag, which determines whether we want the map to be able to control search.
   */
  isSearchable: PropTypes.bool,

  /**
   * Array of result objects formatted for the map returned by SearchApi.
   */
  results: PropTypes.arrayOf(
    PropTypes.shape({
      buildingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      buildingLocation: PropTypes.shape({
        coordinates: PropTypes.arrayOf(PropTypes.number),
        type: PropTypes.string,
      }),
      pricePlanId: PropTypes.number,
    }),
  ),

  /**
   * Search query string section of URL parsed as an object.
   * We use this in order to get matching priceplan data for
   * NanoCards on Map.
   */
  searchQuery: PropTypes.shape({
    area: PropTypes.string,
    budgetMax: PropTypes.string,
    budgetMin: PropTypes.string,
    facilities: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    officeType: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    page: PropTypes.string,
    peopleMax: PropTypes.string,
    peopleMin: PropTypes.string,
    pricePerPersonMax: PropTypes.string,
    pricePerPersonMin: PropTypes.string,
    searchAreaId: PropTypes.string,
    sortBy: PropTypes.string,
  }),

  /**
   * GeoJSON object, usually returned by the AreasApi.
   * https://geojson.org/
   */
  shape: PropTypes.shape({}),

  /**
   * The callback for when we want changes in the map to update the search query.
   */
  submitQuery: PropTypes.func,
};

export default Map;
