import cn from 'classnames';
import React from 'react';

import { AccountIcon } from 'site-react/components/utility';
import useUser from 'site-react/hooks/useUser';

import styles from './AccountItem.module.css';

const AccountItem = ({ className, children }) => {
  const { user } = useUser();

  return (
    <>
      <AccountIcon
        className={cn(className, styles['AccountItem-accountIcon'])}
        hover
        name={user?.name}
        size={38}
      />
      <span className={styles['AccountItem-accountLabel']}>{children}</span>
    </>
  );
};

export default AccountItem;
