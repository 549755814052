import cn from 'classnames';
import { useRouter, usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useMemo, useEffect } from 'react';

import { Button } from 'site-react/components/form';
import { LinkAsButton } from 'site-react/components/navigation';
import {
  Heading,
  Paragraph,
  TextWithIcon,
} from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import { ProductPriceFormatter } from 'site-react/helpers/productPriceFormatter';
import usePermissions from 'site-react/hooks/usePermissions';
import usePriceType from 'site-react/hooks/usePriceType';
import useSearchParamsState from 'site-react/hooks/useSearchParamsState';
import useUser from 'site-react/hooks/useUser';

import styles from './BookThisSpace.module.css';
import DropdownProducts from './DropdownProducts';
import getBookingCTA from '../../helpers/getBookingCTA';
import getCompoundMeetingRoomId from '../../helpers/getCompoundMeetingRoomId';

const BookThisSpace = ({ building, city, isDisabled }) => {
  const router = useRouter();
  const pathname = usePathname();
  const { canRequestPrivateOffice, canRequestMeetingRoom } = usePermissions();
  const { isLoggedIn } = useUser();
  const priceType = usePriceType();

  const [params, setParams] = useSearchParamsState();

  const filteredInstantBookProducts = useMemo(() => {
    // if instant book is available only
    // displays products that can actually be
    // instantly booked
    if (building.isInstantBookAvailable) {
      return building.products.filter((product) => product.isInstantlyBookable);
    } else {
      return building.products;
    }
  }, [building.isInstantBookAvailable, building.products]);

  const coworkingProduct = useMemo(
    () =>
      building.products.find((product) => product.productType === 'coworking'),
    [building.products],
  );

  const meetingRooms = filteredInstantBookProducts.filter(
    (product) => product.productType === 'meeting-room',
  );

  const privateOffice = useMemo(
    () =>
      building.products.find(
        (product) => product.productType === 'private-office',
      ),
    [building.products],
  );

  useEffect(() => {
    if (!params.option) {
      let option = building.products[0].productType;

      if (coworkingProduct) {
        option = 'coworking';
      } else if (option === 'meeting-room') {
        option = getCompoundMeetingRoomId(
          building.products[0],
          [null, undefined].includes(building.products[0].pricePerHour) &&
            'full-day',
        );
      }

      setParams({
        ...params,
        option,
      });
    }
  }, [params, setParams, coworkingProduct, building.products]);

  const dropdownOptions = useMemo(() => {
    const options = [];

    const priceFormatter = new ProductPriceFormatter('en-GB', 'GBP');

    if (coworkingProduct) {
      const coworkingProductPrice = priceFormatter.getFormattedPrice(
        coworkingProduct[priceFormatter.priceTypeConfig[priceType]],
        priceType,
      );

      options.push({
        description: `${coworkingProductPrice} / person / day`,
        id: coworkingProduct.productType,
        title: 'Coworking Space',
      });
    }

    if (meetingRooms?.length) {
      meetingRooms.forEach((meetingRoom) => {
        if (![null, undefined].includes(meetingRoom.pricePerHour)) {
          const meetingRoomPrice = priceFormatter.getFormattedPrice(
            meetingRoom[
              priceFormatter.meetingRoomPriceConfig.perHour[priceType]
            ],
            priceType,
          );

          options.push({
            capacity: meetingRoom.capacity,
            description: '',
            id: `${meetingRoom.capacity}person-${meetingRoom.productType}-${meetingRoom.pricePerHour}ph`,
            pricePerHour: meetingRoom.pricePerHour,
            title: `${meetingRoom.capacity}-person Meeting Room - ${meetingRoomPrice} / hour`,
          });
        }

        if (![null, undefined].includes(meetingRoom.pricePerDay)) {
          const meetingRoomPrice = priceFormatter.getFormattedPrice(
            meetingRoom[
              priceFormatter.meetingRoomPriceConfig['perDay'][priceType]
            ],
            priceType,
          );

          options.push({
            capacity: meetingRoom.capacity,
            description: '',
            id: `${meetingRoom.capacity}person-${meetingRoom.productType}-${meetingRoom.pricePerDay}pd`,
            pricePerDay: meetingRoom.pricePerDay,
            title: `${meetingRoom.capacity}-person Meeting Room - ${meetingRoomPrice} / day`,
          });
        }
      });
    }

    if (privateOffice) {
      const privateOfficeProductPrice = priceFormatter.getFormattedPrice(
        privateOffice[priceFormatter.priceTypeConfig[priceType]],
        priceType,
      );

      options.push({
        description: `${privateOfficeProductPrice} / person / day`,
        id: privateOffice.productType,
        title: 'Private Day Office',
      });
    }

    return options;
  }, [coworkingProduct, meetingRooms, priceType, privateOffice]);

  const isMatchWithQueryString = dropdownOptions?.find(
    (dropdownOption) => dropdownOption.id === params.option,
  );

  const defaultSelectedOption =
    coworkingProduct?.productType ?? dropdownOptions[0].id;

  const selectedOption = params.option ?? defaultSelectedOption;

  useEffect(() => {
    if (!isMatchWithQueryString) {
      setParams({ option: defaultSelectedOption });
    }
  }, [defaultSelectedOption, isMatchWithQueryString, setParams]);

  const DropdownSelector = () => {
    if (isLoggedIn) {
      if (['coworking', 'private-office'].includes(selectedOption)) {
        if (selectedOption === 'private-office' && !canRequestPrivateOffice) {
          return (
            <span
              className={cn(
                'typographyLabel1',
                styles['DropdownSelector-askAdminLabel'],
              )}
            >
              Contact your company admin to book
            </span>
          );
        } else {
          return (
            <Button
              analyticsMetadata={{
                buildingId: building.id,
                city: city?.name,
                location: 'sidebar',
              }}
              disabled={isDisabled}
              isBlock
              name={`${getBookingCTA(
                selectedOption,
                true,
              )} [${selectedOption}]`}
              onClick={() => {
                router.push({
                  pathname: `${pathname}/book-${selectedOption}`,
                  query: {
                    option: selectedOption,
                  },
                });
              }}
              styleAtSmall="auto"
              type="button"
            >
              <TextWithIcon
                contentType="content2"
                iconName={selectedOption === 'coworking' ? 'bolt' : ''}
                iconPosition="left"
                iconSize="md"
                text={getBookingCTA(selectedOption, true)}
              />
            </Button>
          );
        }
      } else {
        if (canRequestMeetingRoom) {
          const selectedMeetingRoom = dropdownOptions.find(
            (item) => item.id === selectedOption,
          );
          const isFullDayMeetingRoom =
            getCompoundMeetingRoomId(selectedMeetingRoom, 'full-day') ===
            selectedOption;

          return (
            <Button
              isBlock
              name={`${getBookingCTA('meeting-room', true)} [meeting-room]`}
              onClick={() => {
                const compoundMeetingRoomId = getCompoundMeetingRoomId(
                  selectedMeetingRoom,
                  isFullDayMeetingRoom && 'full-day',
                );
                router.push({
                  pathname: `${pathname}/book-meeting-room`,
                  query: {
                    option: compoundMeetingRoomId,
                  },
                });
              }}
              styleAtSmall="auto"
              styleType="primary"
              type="button"
            >
              <TextWithIcon
                contentType="content2"
                iconName={building.isInstantBookAvailable ? 'bolt' : ''}
                iconPosition="left"
                iconSize="md"
                text={getBookingCTA(
                  'meeting-room',
                  true,
                  building.isInstantBookAvailable,
                )}
              />
            </Button>
          );
        } else {
          return (
            <span
              className={cn(
                'typographyLabel1',
                styles['DropdownSelector-askAdminLabel'],
              )}
            >
              Contact your company admin to book
            </span>
          );
        }
      }
    } else {
      //anchor down to PassProducts and handle auth/booking from there
      return (
        <LinkAsButton
          href="#products"
          isBlock
          name="btn-scroll-down-to-Pass-products"
          styleAtSmall="auto"
        >
          Book
        </LinkAsButton>
      );
    }
  };

  return (
    <div className={styles['BookThisSpace-wrapper']}>
      <div
        className={cn(
          styles['BookThisSpace-header'],
          styles['BookThisSpace-desktopOnly'],
        )}
      >
        <Heading level="2" type="title3">
          Book a workspace
        </Heading>
        <VerticalSpacing size="md" />
      </div>
      {isLoggedIn && (
        <>
          {dropdownOptions.length === 1 ? (
            <Paragraph isMarginless type="content2">
              {dropdownOptions[0].description}
            </Paragraph>
          ) : (
            <DropdownProducts
              isDisabled={isDisabled}
              name="product-select"
              onChange={(id) => setParams({ ...params, option: id })}
              options={dropdownOptions}
              selectedId={selectedOption}
            />
          )}
          <VerticalSpacing size="md" />
        </>
      )}
      <div className={styles['BookThisSpace-desktopOnly']}>
        <VerticalSpacing size="md" />
      </div>
      <DropdownSelector />
    </div>
  );
};

BookThisSpace.propTypes = {
  building: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  city: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  isDisabled: PropTypes.bool,
};

export default BookThisSpace;
