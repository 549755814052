import PropTypes from 'prop-types';
import React from 'react';

import { UnstyledButton } from 'site-react/components/form';
import { MaterialIcon } from 'site-react/components/typography';
import { analyticsMetadataPropTypes } from 'site-react/helpers/Analytics';

import styles from './ImageCarouselExpandButton.module.css';

const ImageCarouselExpandButton = ({
  analyticsMetadata = {},
  label = '',
  onClick,
}) => (
  <UnstyledButton
    analyticsMetadata={analyticsMetadata}
    attributes={{
      'aria-label': 'Open full screen carousel',
    }}
    className={styles['ImageCarouselExpandButton-button']}
    elementType="button"
    label={label}
    onClick={onClick}
  >
    <span className={styles['ImageCarouselExpandButton-iconWrapper']}>
      <MaterialIcon
        className={styles['ImageCarouselExpandButton-icon']}
        iconType="zoom_out_map"
      />
    </span>
  </UnstyledButton>
);

ImageCarouselExpandButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * Label text that represents this button.
   * This will be the main prop sent to analytics; key of button
   */
  label: PropTypes.string,

  /**
   * Callback when the button activates
   */
  onClick: PropTypes.func.isRequired,
};

export default ImageCarouselExpandButton;
