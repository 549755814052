/**
░░░░░░░WKkxdx0N░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░WN0xdoc:oK░░░░░░░░░░░░░░░░░░░░░░░░
░░WX0O00Oxxkxc;dN░░░░░░░░░░░░░░░░░░░░░░░
░░Xxdox00kxdoc;oN░░░░░░░░░░░░░░░░░░░░░░░
░░Nkok0Kklcc:;:0░░░░░░░░░░░░░░░░░░░░░░░░
░░░WNNOxddoc;lKW░░░░░░░░░░░░░░░░░░░░░░░░
░░░░NOkkdoc;,okkxddddddxOKXW░░WNNNNW░░░░
░░░W0xxxdl::;;;;::::;;;;;;cok00OO000X░░░
░░░WOdddolcc::c:llolc:,;,,;;,,;codkk0W░░
░░░░Kolllccc:cc:cldxxc,,;;;:;,'.;odkK░░░
░░░░W0l:::clc:cclkOdl;';:::::;'.'ox0W░░░
░░░░░░Xkl:ldl;;cokOkdc,;;::::;,'':ON░░░░
░░░░░░░░Nkdodl::dkOO0kc,,,;;;;;,.:K░░░░░
░░░░░░░░░WXkoll::clodo:'''',;;;:oKW░░░░░
░░░░░░░░░░░WN0kolcc:::::;,:lok0XW░░░░░░░
░░░░░░░░░░░░░░W0lcoollllcxKNW░░░░░░░░░░░
░░░░░░░░░░░░░░░Nd;lk0xlloK░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░Ndl0WW0ldKW░░░░░░░░░░░░░░
░░░░░░░░░N0O0KKkcdNNXdlK░░░░░░░░░░░░░░░░
░░░░░░░░W0dlclxkxkkdlcoKN░░░░░░░░░░░░░░░
░░░░░░░░░░NOOK0xddoccok0XW░░░░░░░░░░░░░░
░░░░░░░░░░░░░Nkxxod0XN░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░NXWKkX░░░░░░░░░░░░░░░░░░░░░
 *
 * DEPRECATION NOTICE
 *
 * We have created a new Modal component under components/page/ModalNew.
 * The hope is that this new way of implementing a Modal is simpler to reason about and more
 * semantically correct as it leverages the dialog element which handles a lot of fiddly behaviour
 * like focus trapping, scroll locking, background effects and key events out of the box.
 * If you need to implement a Modal or are currently working on a task that involves maintenance of a page
 * using this component please consider migrating to the new Modal component pattern.
 */
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Modal.module.css';

const ModalBackground = ({ onClickCallback = () => {} }) => (
  // role="presentation", as handler only being used to capture bubbled events
  <div
    className={styles.ModalBackground}
    onClick={onClickCallback}
    role="presentation"
  />
);

ModalBackground.propTypes = {
  onClickCallback: PropTypes.func,
};

export default ModalBackground;
