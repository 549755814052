import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import { DrawerContext } from './context/DrawerContext';
import styles from './Drawer.module.css';

const DRAWER_STATES = {
  close: 'Closed',
  open: 'Opened',
};

const Drawer = ({
  analyticsMetadata = {},
  children,
  label,
  onCloseCallback = () => {},
  onOpenCallback = () => {},
  renderTrigger,
  ...props
}) => {
  if (props?.open) {
    throw new Error(
      'The Drawer component should be opened using the .show() or .showDrawer() methods - not by passing an `open` attribute. If a <dialog> is opened using the `open` attribute, it will be non-modal.',
    );
  }

  const [isRenderable, setIsRenderable] = useState(false);
  useEffect(() => {
    // This is to prevent the drawer from rendering on the server, where document
    // is not defined.
    //
    // Using an effect in this way is a common pattern for ensuring that code
    // that relies on the DOM only runs in the browser, without causing
    // hydration mismatches.
    setIsRenderable(true);
  }, []);

  // We only want to render the children of the drawer when it is open. Otherwise:
  // - we can get conflicts between element IDs
  // - we're spending CPU cycles rendering things that aren't visible
  const [renderChildren, setRenderChildren] = useState(false);

  const dialog = useRef(null);

  const handleAnalytics = useCallback(
    (drawer) => {
      analytics.track(
        `Drawer ${drawer}`,
        {
          label,
          ...analyticsMetadata,
        },
        {
          sendPageProperties: true,
        },
      );
    },
    [analyticsMetadata, label],
  );

  const handleOpenDrawer = useCallback(() => {
    setRenderChildren(true);
    handleAnalytics(DRAWER_STATES.open);

    document.body.classList.add(['u-preventScroll']);

    if (!dialog.current?.open) {
      dialog.current?.showModal();
    }

    onOpenCallback();
  }, [handleAnalytics, onOpenCallback]);

  const handleCloseDrawer = useCallback(() => {
    if (dialog.current?.open) {
      dialog.current?.close();
    }
  }, []);

  const handleCloseDialog = () => {
    setRenderChildren(false);
    handleAnalytics(DRAWER_STATES.close);

    document.body.classList.remove('u-preventScroll');
    onCloseCallback();
  };

  // This allows us to use certain functions
  // or values anywhere within a drawer without
  // prop drilling.
  const contextValue = useMemo(() => {
    return {
      closeDrawer: handleCloseDrawer,
    };
  }, [handleCloseDrawer]);

  return (
    <>
      {renderTrigger({
        openDrawer: handleOpenDrawer,
      })}
      {isRenderable
        ? createPortal(
            <dialog
              className={styles.Drawer}
              onClick={(event) => {
                event.stopPropagation();
                if (event.target.localName === 'dialog') {
                  handleCloseDrawer();
                }
              }}
              onClose={handleCloseDialog}
              ref={dialog}
              {...props}
            >
              {renderChildren ? (
                <DrawerContext.Provider value={contextValue}>
                  <div className={styles['Drawer-content']}>{children}</div>
                </DrawerContext.Provider>
              ) : null}
            </dialog>,
            document.body,
          )
        : null}
    </>
  );
};

Drawer.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,

  /**
   * The content to show inside this drawer.
   *
   * If this is a function, on render it will be called with a
   * `closeDrawer` argument.
   *
   * Example:
   * ```
   * <Drawer>Content goes here</Drawer>
   * ```
   * ```
   * <Drawer>
   *   {({ closeDrawer }) => (
   *     <div>
   *       Content goes here
   *       <button onClick={closeDrawer}>Close drawer</button>
   *     </div>
   *   )}
   * </Drawer>
   * ```
   */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),

  /**
   * Label to be sent with analytics event
   */
  label: PropTypes.string.isRequired,

  /**
   * Optional function to be called when the drawer is closed.
   */
  onCloseCallback: PropTypes.func,

  /**
   * Optional function to be called when the drawer is closed.
   */
  onOpenCallback: PropTypes.func,

  /**
   * Render prop for the trigger. A function, which returns a component that
   * gets rendered in place. The function will be called with an openDrawer
   * argument, which can be called to open the associated drawer.
   *   *
   * Example:
   * ```
   * <Drawer renderTrigger={
   *   ({ openDrawer }) => <button onClick={openDrawer}>Open drawer</button>
   * } />`
   * ```
   */
  renderTrigger: PropTypes.func.isRequired,
};

export default Drawer;
