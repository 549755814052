import React from 'react';

import { BuildingPropTypes } from 'site-react/proptypes';

import ArchivedBanner from './ArchivedBanner';
import styles from './PassActions.module.css';
import BookThisSpace from '../BookThisSpace';

const PassActions = ({ building }) => {
  return (
    <div className={styles.PassActions}>
      <div className={styles['PassActions-panel']}>
        {building.onDemandStatus === 'archived' ? (
          <ArchivedBanner building={building} />
        ) : (
          <BookThisSpace building={building} city={building.city} />
        )}
      </div>
    </div>
  );
};

PassActions.propTypes = {
  building: BuildingPropTypes.isRequired,
};

export default PassActions;
