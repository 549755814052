import cn from 'classnames';
import { useRouter, usePathname } from 'next/navigation';

import { Button } from 'site-react/components/form';
import { TextWithIcon } from 'site-react/components/typography';
import useUser from 'site-react/hooks/useUser';

import styles from './BookingButton.module.css';
import getBookingCTA from '../../helpers/getBookingCTA';

const BookingButton = ({
  building,
  product,
  buttonType = 'meeting-room',
  isEnabled = true,
}) => {
  const router = useRouter();
  const pathname = usePathname();

  const { isLoggedIn } = useUser();

  let status = '';

  if (buttonType !== 'meeting-room' && isLoggedIn) {
    status = 'pass-product-logged-in';
  } else if (buttonType !== 'meeting-room' && !isLoggedIn) {
    status = 'pass-product-logged-out';
  }

  if (status === 'pass-product-logged-in') {
    return (
      <div className={styles['BookingButton-buttonContainer']}>
        {isEnabled ? (
          <Button
            analyticsMetadata={{
              buildingId: building.id,
              city: building.city?.name,
              location: 'products',
            }}
            isCompact
            name="Book"
            onClick={() => {
              router.push({
                pathname: `${pathname}/book-${product.productType}`,
                query: {
                  option: product.productType,
                },
              });
            }}
            styleAtSmall="auto"
            type="button"
          >
            {product.productType === 'coworking' ? (
              <TextWithIcon
                contentType="content2"
                iconName="bolt"
                iconPosition="left"
                iconSize="md"
                text={getBookingCTA(product.productType, isLoggedIn)}
              />
            ) : (
              <>{getBookingCTA(product.productType, isLoggedIn)}</>
            )}
          </Button>
        ) : (
          <span
            className={cn(
              styles['BookingButton-adminLabel'],
              'u-typographySmallLabel',
            )}
          >
            Contact your company admin to book
          </span>
        )}
      </div>
    );
  } else if (status === 'pass-product-logged-out') {
    return (
      <Button
        isCompact
        name={`${getBookingCTA(product.productType, isLoggedIn)} [${
          product.productType
        }]`}
        onClick={() => {
          router.push({
            pathname: `${pathname}/book-${product.productType}`,
            query: {
              option: product.productType,
            },
          });
        }}
        styleAtSmall="auto"
        type="button"
      >
        {product.productType === 'coworking' ? (
          <TextWithIcon
            contentType="content2"
            iconName="bolt"
            iconPosition="left"
            iconSize="md"
            text={getBookingCTA(product.productType, isLoggedIn)}
          />
        ) : (
          <>{getBookingCTA(product.productType, isLoggedIn)}</>
        )}
      </Button>
    );
  }
};

export default BookingButton;
