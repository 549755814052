import PropTypes from 'prop-types';
import React from 'react';

import { RuledHeading } from 'site-react/components/typography';
import TransitStop from 'site-react/features/TransitStop';
import transformLandmarksToNearbyLocations from 'site-react/helpers/transformLandmarksToNearbyLocations';

import styles from './Location.module.css';
import MiniMap from './MiniMap';

const Location = ({
  buildingId,
  buildingName,
  isPass,
  landmarks,
  lat,
  lng,
  postcode,
}) => {
  const nearbyLocations = transformLandmarksToNearbyLocations(landmarks, 3);

  return (
    <section className={styles['Location']}>
      <div className={styles['Location-heading']}>
        <RuledHeading level="2">Location</RuledHeading>
      </div>
      <div className={styles['Location-miniMap']}>
        <MiniMap
          areaName={postcode}
          buildingName={buildingName}
          lat={lat}
          lng={lng}
          trackingId={buildingId}
        />
      </div>
      {nearbyLocations.length ? (
        <ol
          aria-label="Nearby Transit Stops"
          className={styles['Location-list']}
        >
          {nearbyLocations
            .filter(({ kind }) => kind === 'transit-stop')
            .map(({ isSearchArea, lines, name, slug, walkingMinutes }) => (
              <li aria-label="Transit Stop" key={slug}>
                <TransitStop
                  isPass={isPass}
                  isSearchArea={isSearchArea}
                  lines={lines}
                  name={name}
                  slug={slug}
                  walkingMinutes={walkingMinutes}
                />
              </li>
            ))}
        </ol>
      ) : null}
    </section>
  );
};

Location.propTypes = {
  buildingId: PropTypes.number.isRequired,
  buildingName: PropTypes.string.isRequired,
  isPass: PropTypes.bool,
  landmarks: PropTypes.arrayOf(
    PropTypes.shape({
      distance: PropTypes.number.isRequired,
      landmark: PropTypes.shape({
        images: PropTypes.arrayOf(
          PropTypes.shape({
            img: PropTypes.string.isRequired,
          }),
        ),
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  postcode: PropTypes.string.isRequired,
};

export default Location;
