import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { CloseButton } from 'site-react/components/navigation';
import { MaterialIcon } from 'site-react/components/typography';

import styles from './SearchAsIMoveToggle.module.css';

/**
 * Use 'SearchAsIMove' to enable or disable searching as the user drags the map.
 * Will appear on a map if isSearchable prop is true.
 */
const SearchAsIMoveToggle = ({ onToggle = () => {}, status = 'active' }) => (
  <>
    {status === 'no-results' && (
      <div
        className={cn(
          styles['SearchAsIMoveToggle'],
          styles['SearchAsIMoveToggle--noResults'],
        )}
        data-testid="SearchAsIMoveToggle-no-results"
      >
        <span className={styles['SearchAsIMoveToggle-labelSpacing']}>
          No results. Try zooming out
        </span>
        <CloseButton color="white" onClick={() => onToggle('inactive')} />
      </div>
    )}
    {status === 'active' && (
      <button
        className={styles['SearchAsIMoveToggle']}
        data-testid="SearchAsIMoveToggle-active"
        onClick={() => {
          onToggle('inactive');
        }}
        type="button"
      >
        <div
          className={
            styles[('SearchAsIMoveToggle-checkbox', styles['is-checked'])]
          }
        >
          <MaterialIcon
            className={styles['SearchAsIMoveToggle-checkedIcon']}
            iconType="done"
          />
          <input
            checked
            className={styles['SearchAsIMoveToggle-checkboxType']}
            name="active"
            readOnly
            type="checkbox"
            value=""
          />
        </div>
        <span>Search as I move the map</span>
      </button>
    )}
    {status === 'inactive' && (
      <button
        className={styles['SearchAsIMoveToggle']}
        data-testid="SearchAsIMoveToggle-inactive"
        onClick={() => {
          onToggle('active');
        }}
        type="button"
      >
        <div className={styles['SearchAsIMoveToggle-checkbox']}>
          <input
            checked
            className={styles['SearchAsIMoveToggle-checkboxType']}
            name="inactive"
            readOnly
            type="checkbox"
            value=""
          />
        </div>
        <span>Search as I move the map</span>
      </button>
    )}
    {status === 'redo-search' && (
      <button
        className={cn(
          styles['SearchAsIMoveToggle'],
          styles['SearchAsIMoveToggle--redoSearch'],
        )}
        data-testid="SearchAsIMoveToggle-redo-search"
        onClick={() => {
          onToggle('active');
        }}
        type="button"
      >
        <span className={styles['SearchAsIMoveToggle-redoSearchLabel']}>
          <MaterialIcon
            className={styles['SearchAsIMoveToggle-labelSpacing']}
            iconType="cached"
          />
          Redo search here
        </span>
      </button>
    )}
  </>
);

export default SearchAsIMoveToggle;

SearchAsIMoveToggle.propTypes = {
  /**
   * Method to change the status of the component when a user interacts with the toggle.
   */
  onToggle: PropTypes.func,
  /**
   * Tells the component which type of toggle to render:
   *
   * active: User is searching as they scroll. If they click the toggle the status changes
   * to inactive.
   *
   * inactive: User is not searching as they scroll. If they click the toggle the status
   * changes to active. If they continue to scroll the status changes to redo-search.
   *
   * redo-search: Variation of inactive. If they click the toggle status changes to active.
   *
   * no-results: When triggered, moves from an active variant to an inactive variant. If the user
   * clicks the close button the status changes to inactive. If they continue to scroll the status
   * will change to redo-search.
   *
   */
  status: PropTypes.oneOf(['active', 'inactive', 'no-results', 'redo-search']),
};
