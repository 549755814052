import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import Analytics from 'site-react/helpers/Analytics';

import styles from './TransitStop.module.css';

const onTransitStopClicked = (slug) => {
  Analytics.track('Listing Location Clicked', {
    name: slug,
  });
};

const TransitStop = ({
  isPass,
  isSearchArea = false,
  lines = [],
  name,
  slug,
  walkingMinutes,
}) => {
  const passLink = '/pass/london/';

  return (
    <div className={styles['TransitStop-wrapper']}>
      {isSearchArea ? (
        <NextLink
          className={styles['TransitStop-title']}
          href={`${isPass ? passLink : '/office-space-london/'}${slug}`}
          onClick={() => onTransitStopClicked(slug)}
        >
          {name}
        </NextLink>
      ) : (
        <span className={styles['TransitStop-title']}>{name}</span>
      )}
      <span className={styles['TransitStop-walkingTime']}>
        {walkingMinutes}min walk
      </span>
      {lines.length ? (
        <ul
          aria-label="Transit Stops"
          className={styles['TransitStop-listWrapper']}
        >
          {lines.map((line) => (
            <li className={styles['TransitStop-listItem']} key={line.image}>
              <img
                alt={line.description || ''}
                className={styles['TransitStop-lineImage']}
                src={line.image}
                title={line.description || ''}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

TransitStop.propTypes = {
  /**
   * Is the name of the transit stop displayed with an anchor
   * linking back to search page.
   */
  isSearchArea: PropTypes.bool,
  /**
   * Lines, or connections, that are attached to this stop.
   */
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      image: PropTypes.string.isRequired,
    }),
  ),

  /**
   * Name of the stop
   */
  name: PropTypes.string.isRequired,

  /**
   * kebab-cased string of the transit stop name.
   */
  slug: PropTypes.string.isRequired,

  /**
   * How many minutes does it take to walk from the primary location to this stop?
   */
  walkingMinutes: PropTypes.number.isRequired,
};

export default TransitStop;
