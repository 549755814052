import PropTypes from 'prop-types';
import React from 'react';

import styles from './ImageCarouselPosition.module.css';

function RegularImageCarouselPosition({ caption, current, max }) {
  return (
    <span className={styles['RegularImageCarouselPosition-position']}>
      <span className={styles['RegularImageCarouselPosition-backgroundPill']}>
        <span
          aria-hidden
          className={styles['RegularImageCarouselPosition-currentPosition']}
        >
          {String(current).padStart(2, '0')}
        </span>
        <span className={styles['RegularImageCarouselPosition-imageQuantity']}>
          {` / ${String(max).padStart(2, '0')}`}
        </span>
        {caption && (
          <span className={styles['RegularImageCarouselPosition-caption']}>
            {caption}
          </span>
        )}
      </span>
    </span>
  );
}

function FullScreenImageCarouselPosition({ caption, current, max }) {
  return (
    <span className={styles['FullSizeImageCarouselPosition-position']}>
      <b>
        {current}/{max}
        {caption && `: ${caption}`}
      </b>
    </span>
  );
}

const ImageCarouselPosition = ({
  caption = null,
  current,
  enableImageCarouselCaptions = false,
  isFullScreen = false,
  max,
}) => {
  return (
    <>
      <span aria-atomic aria-live="polite" className="u-visuallyHidden">
        Image {current} of {max}
      </span>
      {isFullScreen ? (
        <FullScreenImageCarouselPosition
          caption={enableImageCarouselCaptions ? caption : null}
          current={current}
          max={max}
        />
      ) : (
        <RegularImageCarouselPosition
          caption={enableImageCarouselCaptions ? caption : null}
          current={current}
          max={max}
        />
      )}
    </>
  );
};

ImageCarouselPosition.propTypes = {
  /**
   * The caption for the current image.
   */
  caption: PropTypes.string,

  /**
   * The currently-visible image. 1-indexed.
   */
  current: PropTypes.number.isRequired,

  /**
   * Whether to show captions for the images.
   */
  enableImageCarouselCaptions: PropTypes.bool,

  /**
   * Wether the carousel is full screen or not
   */
  isFullScreen: PropTypes.bool,

  /**
   * The total number of images.
   */
  max: PropTypes.number.isRequired,
};

export default ImageCarouselPosition;
