import { UICard } from 'site-react/components/page';
import {
  Heading,
  MaterialIcon,
  TextWithIcon,
} from 'site-react/components/typography';

import styles from './ProductBookingDetailsWrapper.module.css';

const ProductBookingDetailsWrapper = ({
  heading,
  bookingResponseTime,
  showInstantBook = false,
  children,
}) => {
  const headingContent = (
    <div className={styles['ProductBookingDetailsWrapper-cardHeading']}>
      {heading && (
        <Heading level="3" type="title4">
          {heading}
        </Heading>
      )}
      {!showInstantBook && bookingResponseTime && (
        <span
          className={styles['ProductBookingDetailsWrapper-responseTimeWrapper']}
        >
          <MaterialIcon
            className={styles['ProductBookingDetailsWrapper-icon']}
            iconType="alarm"
          />
          <span>
            <abbr title="Average">Av. </abbr>
            Response time{' '}
            <b>
              {bookingResponseTime} business hour
              {bookingResponseTime > 1 ? 's' : ''}
            </b>
          </span>
        </span>
      )}
      {showInstantBook && (
        <TextWithIcon
          contentType="content3"
          iconName="bolt"
          iconPosition="left"
          text="Instant Book"
        />
      )}
    </div>
  );

  return (
    <div className={styles['ProductBookingDetailsWrapper-requestToBook']}>
      <UICard style={{ padding: 0 }}>
        {headingContent}
        {children}
      </UICard>
    </div>
  );
};

export default ProductBookingDetailsWrapper;
