import breakpoints from './breakpoints';
import colors from './colors';
import grid from './grid';
import shadow from './shadow';
import spacing from './spacing';

const theme = {
  breakpoints,
  colors,
  grid,
  shadow,
  spacing,
};

export default theme;
