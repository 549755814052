import PropTypes from 'prop-types';
import React from 'react';

import { UICard } from 'site-react/components/page';
import { Heading } from 'site-react/components/typography';
import { VerticalSpacing } from 'site-react/components/utility';
import { BuildingPropTypes } from 'site-react/proptypes';

import styles from './BookingSummary.module.css';

const BookingSummary = ({ building, dataPoints }) => {
  const coverPhoto = building.images[0].img;

  return (
    <UICard style={{ padding: `var(--space-lg)` }}>
      <Heading level="3" type="title4">
        {building.name}
      </Heading>
      <VerticalSpacing size="md" />
      <img
        alt="Building"
        className={styles['BookingSummary-image']}
        loading="lazy"
        src={coverPhoto}
      />
      <dl className={styles['BookingSummary-dataList']}>
        {dataPoints.map((entry) => (
          <div data-testid={`${entry.term}-datapoint`} key={entry.term}>
            <dt>
              <b>{entry.term}</b>
            </dt>
            <dd className={styles['BookingSummary-description']}>
              {entry.description}
            </dd>
          </div>
        ))}
      </dl>
    </UICard>
  );
};

BookingSummary.propTypes = {
  building: BuildingPropTypes.isRequired,
  dataPoints: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
      term: PropTypes.string,
    }),
  ).isRequired,
};

export default BookingSummary;
