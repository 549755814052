import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import styles from './ImageCarouselItem.module.css';

const ImageCarouselItem = ({
  alt = '',
  isFullScreen = false,
  prefetch = false,
  selected = false,
  sizes = null,
  src,
  srcSet = null,
}) => {
  const [loadable, setLoadable] = useState(selected || prefetch);

  const imageRef = useRef();

  useEffect(() => {
    if (selected) {
      imageRef.current.focus();
    }
    setLoadable(selected || prefetch);
  }, [loadable, selected, prefetch]);

  return (
    <li
      className={cn(styles.ImageCarouselItem, {
        [styles['is-selected']]: selected,
      })}
    >
      <img
        alt={alt}
        className={styles['ImageCarouselItem-image']}
        data-testid="ImageCarouselItem-imgTag"
        ref={imageRef}
        sizes={isFullScreen ? '100vw' : sizes}
        src={loadable ? src : ''}
        srcSet={loadable ? srcSet : ''}
        tabIndex={-1 /* Allows JS to force focus, for accessibility */}
      />
    </li>
  );
};

ImageCarouselItem.propTypes = {
  /**
   * The alt text for this image
   */
  alt: PropTypes.string,

  /**
   * Wether the carousel is full screen or not
   */
  isFullScreen: PropTypes.bool,

  /**
   * Should we start loading this image, so it's ready when it gets displayed?
   *
   * NOTE: This will get set automatically by ImageCarousel. Do not specify
   * it in your props.
   * NOTE: this is actually used, but only in getDerivedStateFromProps. eslint
   * throws an inaccurate warning.
   */
  prefetch: PropTypes.bool /* eslint-disable-line react/no-unused-prop-types */,

  /**
   * Is this the currently-visible item?
   *
   * NOTE: This will get set automatically by ImageCarousel. Do not specify
   * it in your props.
   */
  selected: PropTypes.bool,

  /**
   * The sizes attribute. Passed through to the underlying <img />.
   *
   * See also: https://developer.mozilla.org/en-US/docs/Learn/HTML/Multimedia_and_embedding/Responsive_images
   */
  sizes: PropTypes.string,

  /**
   * The URL for this image.
   */
  src: PropTypes.string.isRequired,

  /**
   * A srcset for this image.
   */
  srcSet: PropTypes.string,
};

export default ImageCarouselItem;
