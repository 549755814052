import PropTypes from 'prop-types';
import React from 'react';

import styles from './Tour360ContentPopup.module.css';

const Tour360ContentPopup = ({ tourUrl }) => {
  return (
    <div>
      <div className={styles['Tour360ContentPopup-wrapper']}>
        <iframe
          allow="vr"
          allowFullScreen
          border="0"
          className={styles['Tour360ContentPopup-iframe']}
          frameBorder="0"
          scrolling="no"
          src={tourUrl}
          title="360° Tour"
        />
      </div>
    </div>
  );
};

Tour360ContentPopup.propTypes = {
  /**
   * The url for the 360 tour that is available in the building object
   */
  tourUrl: PropTypes.string.isRequired,
};

export default Tour360ContentPopup;
