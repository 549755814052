import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';
import { BottomAccessoryButton } from 'site-react/features/ImageCarousel';
import analytics, {
  analyticsMetadataPropTypes,
} from 'site-react/helpers/Analytics';

import styles from './ShortlistButton.module.css';

const ShortlistButton = ({
  analyticsMetadata = {},
  isActivated = false,
  isEnabled = true,
  isLoading = false,
  label = null,
  onClick = () => {},
  testId = 'ShortlistButton',
  variant = 'default',
}) => {
  const onFavouriteClick = (...args) => {
    analytics.track(
      'Favourite button clicked',
      {
        ...analyticsMetadata,
        isFavouriting: !isActivated,
      },
      {
        sendPageProperties: true,
      },
    );
    onClick(...args);
  };

  return variant === 'carousel' ? (
    <BottomAccessoryButton
      enabledIconColor={isActivated ? 'brandprimary' : 'neutral-900'}
      iconType={isActivated ? 'favorite' : 'favorite_border'}
      isLoading={isLoading}
      label={isActivated ? 'Saved' : label}
      onClick={onFavouriteClick}
      title={
        isActivated
          ? 'Remove office from shortlists'
          : 'Add office to shortlists!'
      }
    />
  ) : (
    <button
      className={cn(
        styles['ShortlistButton'],
        variant === 'nav'
          ? styles['ShortlistButton--saveAndCompareButton']
          : styles['ShortlistButton--saveButton'],
        label && !isLoading
          ? styles['ShortlistButton--iconLabel']
          : styles['ShortlistButton--iconOnly'],
        {
          [styles['ShortlistButton--iconLabelNav']]: variant === 'nav',
          [styles['ShortlistButton--loadingButton']]: isLoading,
        },
      )}
      data-testid={testId}
      disabled={!isEnabled}
      onClick={onFavouriteClick}
      title={
        isActivated
          ? 'Remove office from shortlists'
          : 'Add office to shortlists!'
      }
      type="button"
    >
      {isLoading && <div className={styles['ShortlistButton-loading']} />}
      {!isLoading && (
        <>
          <MaterialIcon
            className={cn(
              styles['ShortlistButton-icon'],
              isActivated && styles['ShortlistButton-shortlistedIcon'],
            )}
            iconType={isActivated ? 'favorite' : 'favorite_border'}
          />
          {label && (
            <span
              className={
                variant === 'nav'
                  ? styles['ShortlistButton-saveAndCompareButtonlabel']
                  : styles['ShorttlistButton-saveButtonLabel']
              }
            >
              {isActivated ? 'Saved' : label}
            </span>
          )}
        </>
      )}
    </button>
  );
};

ShortlistButton.propTypes = {
  /**
   * Additional metadata that we want to attach to the analytics event on click.
   *
   * Where possible, use existing properties to convey your metadata. In order
   * to maintain consistency across our events, any new properties should be
   * added to this shape.
   *
   * All properties are optional.
   */
  analyticsMetadata: analyticsMetadataPropTypes,
  /**
   * Has this item been shortlisted?
   */
  isActivated: PropTypes.bool,

  /**
   * Is this button enabled?
   */
  isEnabled: PropTypes.bool,

  /**
   * Is the state currently changing?
   */
  isLoading: PropTypes.bool,

  /**
   * Label to appear next to the shortlist button
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  /**
   * Callback when the button is clicked.
   */
  onClick: PropTypes.func,

  /**
   * Optional string to render in a `data-testid` attribute to allow element to
   * be found in tests
   */
  testId: PropTypes.string,

  /**
   * Optional variant type to style differently based on where it's being used
   */
  variant: PropTypes.oneOf(['default', 'carousel', 'nav']),
};

export default ShortlistButton;
