import React from 'react';

import { Heading, ListItemWithIcon } from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';

import styles from './DefaultContent.module.css';
import LogoGrid from '../components/LogoGrid';

const DefaultContent = () => {
  return (
    <aside className={styles['DefaultContent-contentWrapper']}>
      <Heading level="2" type="title3">
        We’re trusted by 2,500+ of the world’s most exciting companies
      </Heading>
      <VerticalSpacing size="lg" />
      <LogoGrid />
      <VerticalSpacing size="lg" />
      <HorizontalRule />
      <VerticalSpacing size="lg" />
      <div className={styles['DefaultContent-heading']}>
        <Heading level="3" type="title3">
          Hubble gives you the power to
        </Heading>
      </div>
      <VerticalSpacing size="sm" />
      <ul className={styles['DefaultContent-list']}>
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Instantly search 5,000+ offices"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Give your team access to the world’s best workspaces, on-demand"
        />
        <ListItemWithIcon
          iconColor="green-200"
          iconName="check_circle"
          text="Understand, manage and share everything workplace related with your team"
        />
      </ul>
    </aside>
  );
};

export default DefaultContent;
