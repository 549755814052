import React from 'react';

import { Heading, ListItemWithIcon } from 'site-react/components/typography';
import { HorizontalRule, VerticalSpacing } from 'site-react/components/utility';

import styles from './HostContent.module.css';
import LogoGrid from '../../LogoGrid';

const HostContent = () => (
  <aside className={styles['HostContent-contentWrapper']}>
    <Heading level="2" type="title3">
      We’re trusted by 2,500+ of the world’s most exciting companies
    </Heading>

    <VerticalSpacing size="lg" />

    <LogoGrid />

    <VerticalSpacing size="lg" />
    <HorizontalRule />
    <VerticalSpacing size="lg" />

    <div className={styles['HostContent-heading']}>
      <Heading level="3" type="title3">
        Hubble gives you the power to
      </Heading>
    </div>
    <VerticalSpacing size="sm" />
    <ul className={styles['HostContent-list']}>
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Over 1,000 searches per month"
      />
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Over 1,500 viewings per month"
      />
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Increase occupancy by listing part of your space to daily on-demand customers"
      />
      <ListItemWithIcon
        iconColor="green-200"
        iconName="check_circle"
        text="Advisory team on hand to help you sell your space"
      />
    </ul>
  </aside>
);

export default HostContent;
