import cn from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { LinkAsButton, NavBar } from 'site-react/components/navigation';
import { LazyDrawer } from 'site-react/components/page/Drawer';
import buttonStyles from 'site-react/components/styles/button.module.css';
import { MaterialIcon } from 'site-react/components/typography';
import config from 'site-react/config';
import { Context as ShortlistContext } from 'site-react/data/core/ShortlistContext';
import getAppUrl from 'site-react/helpers/getAppUrl';
import useFeatureFlags from 'site-react/hooks/useFeatureFlags';
import useUser from 'site-react/hooks/useUser';

import AccountItem from './components/AccountItem';
import LocationMenuContent from './components/LocationMenuContent';
import ResourcesMenuContent from './components/ResourcesMenuContent';
import SearchMenuContent from './components/SearchMenuContent';
import SolutionsMenuContent from './components/SolutionsMenuContent';
import styles from './SiteNavigation.module.css';
import useLinkWithNextParam from './useLinkWithNextParam';

const SiteNavigation = ({ activeWorkspace = null, accessory = null }) => {
  const { enableOnSiteGetAQuoteForm } = useFeatureFlags();
  const { isLoggedIn, isUserLoading, user } = useUser();
  const { shortlist, animateNavIcon } = useContext(ShortlistContext);

  const loginHref = useLinkWithNextParam('/login');
  const logoutHref = useLinkWithNextParam('/logout', true);

  const isAdmin = user?.role === 'admin';

  const pathname = usePathname();
  const appUrl = getAppUrl(config);

  const urlSource = new URL(pathname, appUrl);
  const getAQuoteUrl = new URL(
    `https://search.hubblehq.com/get-a-quote?utm_source=navigation&utm_medium=platform&url_source=${urlSource.toString()}`,
  );

  const shouldDisplayShortlistIcon = isLoggedIn && shortlist.length > 0;
  const getAQuoteExcludePaths = [
    '/pass',
    '/coworking',
    '/meeting-rooms',
    '/private-day-offices',
  ];
  const shouldDisplayGetAQuote =
    !isLoggedIn &&
    pathname &&
    !getAQuoteExcludePaths.some((path) => pathname.startsWith(path));

  return (
    <NavBar
      accessory={
        <div className={styles['SiteNavigation-accessoryContainer']}>
          {accessory}
          {shouldDisplayGetAQuote ? (
            enableOnSiteGetAQuoteForm ? undefined : (
              <div className="u-displayBelowLg">
                <LinkAsButton
                  data-analytics-tag="get-a-quote-nav"
                  href={getAQuoteUrl.toString()}
                  isCompact
                  linkType="OutboundLink"
                  name="Get a quote"
                >
                  <span className={styles['SiteNavigation-buttonColor']}>
                    Get a quote
                  </span>
                </LinkAsButton>
              </div>
            )
          ) : null}
          {shouldDisplayShortlistIcon && (
            <Link
              className={styles['SiteNavigation-visibleBelowLgViewport']}
              href="/dashboard/hq/shortlist"
              title="My shortlist"
            >
              <div className={styles['SiteNavigation-iconContainer']}>
                <MaterialIcon
                  className={styles['SiteNavigation-icon']}
                  iconType="favorite_border"
                  role="presentation"
                />
                {animateNavIcon && (
                  <MaterialIcon
                    className={cn(
                      styles['SiteNavigation-icon'],
                      styles['SiteNavigation-animateIcon'],
                    )}
                    iconType="star"
                    role="presentation"
                  />
                )}
              </div>
            </Link>
          )}
        </div>
      }
      isLogoTextDisplayed={!accessory}
      isUserLoading={isUserLoading}
    >
      {!isLoggedIn && (
        <>
          <NavBar.MenuItem label="Solutions">
            <NavBar.MegaMenu>
              <SolutionsMenuContent />
            </NavBar.MegaMenu>
          </NavBar.MenuItem>
          <NavBar.MenuItem label="Locations">
            <NavBar.MegaMenu>
              <LocationMenuContent />
            </NavBar.MegaMenu>
          </NavBar.MenuItem>
          <NavBar.MenuItem label="Resources">
            <NavBar.MegaMenu>
              <ResourcesMenuContent />
            </NavBar.MegaMenu>
          </NavBar.MenuItem>
          <NavBar.SpacerItem />
        </>
      )}
      <NavBar.MenuItem label="Search">
        <NavBar.MegaMenu>
          <SearchMenuContent activeWorkspace={activeWorkspace} />
        </NavBar.MegaMenu>
      </NavBar.MenuItem>

      {shouldDisplayGetAQuote && (
        <>
          {enableOnSiteGetAQuoteForm ? (
            <LazyDrawer
              label="General enquiry"
              lazyComponent={async () =>
                (await import('site-react/features/GeneralEnquiry')).default
              }
              renderTrigger={({ isLoading, openDrawer }) => (
                <NavBar.ButtonItem
                  customLabelElement={
                    <span className={styles['SiteNavigation-getAQuote']}>
                      Get a quote
                    </span>
                  }
                  disabled={isLoading}
                  isLoading={isLoading}
                  label="Get a quote"
                  onClick={openDrawer}
                />
              )}
            >
              {(GeneralEnquiry) => (
                <div className={styles['SiteNavigation-generalEnquiryWrapper']}>
                  <GeneralEnquiry />
                </div>
              )}
            </LazyDrawer>
          ) : (
            <div className={cn(styles['u-alignCenter'], 'u-displayAboveLg')}>
              <NavBar.LinkItem
                customLabelElement={
                  <div
                    className={cn(
                      buttonStyles['Button'],
                      buttonStyles['Button--compact'],
                      buttonStyles['Button--primary'],
                    )}
                    data-analytics-tag="get-a-quote-nav"
                  >
                    Get a quote
                  </div>
                }
                href={getAQuoteUrl.toString()}
                label="Get a quote"
              />
            </div>
          )}
          <NavBar.LinkItem href="/host" label="List space" />
        </>
      )}
      {isLoggedIn && <NavBar.LinkItem href="/dashboard" label="My Hubble" />}
      {shouldDisplayShortlistIcon && (
        <NavBar.LinkItem
          className={styles['SiteNavigation-visibleAboveLgViewport']}
          customLabelElement={
            <div className={styles['SiteNavigation-iconContainer']}>
              <MaterialIcon
                className={styles['SiteNavigation-icon']}
                iconType="favorite_border"
                role="presentation"
              />
              {animateNavIcon && (
                <MaterialIcon
                  className={cn(
                    styles['SiteNavigation-icon'],
                    styles['SiteNavigation-animateIcon'],
                  )}
                  iconType="star"
                  role="presentation"
                />
              )}
            </div>
          }
          href="/dashboard/hq/shortlist"
          label="My shortlist"
        />
      )}
      {isLoggedIn ? (
        <NavBar.MenuItem
          customLabelElement={<AccountItem>Account</AccountItem>}
          forceOpenInHamburger
          label="Signed In"
          relative
        >
          <NavBar.SimpleMenu>
            <NavBar.SimpleMenu.List>
              <li>
                <Link href="/dashboard/pass/bookings">On-Demand bookings</Link>
              </li>
              {isAdmin ? (
                <li>
                  <Link href="/dashboard/pass">Credits</Link>
                </li>
              ) : null}
              <li>
                <Link href="/dashboard/admin">Profile</Link>
              </li>
              {isAdmin ? (
                <li>
                  <Link href="/dashboard/admin/team">Team</Link>
                </li>
              ) : null}
              <li>
                <Link href={logoutHref}>Logout</Link>
              </li>
            </NavBar.SimpleMenu.List>
          </NavBar.SimpleMenu>
        </NavBar.MenuItem>
      ) : (
        <NavBar.MenuItem forceOpenInHamburger label="Login" relative>
          <NavBar.SimpleMenu>
            <NavBar.SimpleMenu.List>
              <li>
                <Link
                  data-testid="SiteNavigation-customerLogin"
                  href={loginHref}
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  href={`/login?next=${encodeURIComponent('/host/listings')}`}
                >
                  Host login
                </Link>
              </li>
            </NavBar.SimpleMenu.List>
          </NavBar.SimpleMenu>
        </NavBar.MenuItem>
      )}
    </NavBar>
  );
};

SiteNavigation.propTypes = {
  /**
   * An accessory to display in available NavBar whitespace.
   */
  accessory: PropTypes.node,

  /**
   * Which workspace nav item should have active styling applied, if any
   */
  activeWorkspace: PropTypes.oneOf([
    'hq-private-offices',
    'part-time-offices',
    'pass-coworking',
    'pass-meeting-room',
    'pass-private-office',
    null,
  ]),
};

export default SiteNavigation;
