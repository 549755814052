import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Spinner } from 'site-react/components/utility';
import Analytics from 'site-react/helpers/Analytics';

import styles from './NavBarButtonItem.module.css';

const NavBarButtonItem = ({
  className = null,
  customLabelElement = null,
  isLoading = false,
  label,
  onClick = () => {},
}) => {
  const handleClick = useCallback(() => {
    onClick();
    Analytics.track(
      'Button clicked',
      {
        label,
        location: 'navigation',
      },
      {
        sendPageProperties: true,
      },
    );
  }, [label, onClick]);

  return (
    <li aria-label={label} className={cn(styles.NavBarButtonItem, className)}>
      <button
        className={styles['NavBarButtonItem-button']}
        data-testid={`${label} main nav item`}
        disabled={isLoading}
        onClick={handleClick}
        type="button"
      >
        {isLoading ? <Spinner size="small" /> : (customLabelElement ?? label)}
      </button>
    </li>
  );
};

NavBarButtonItem.propTypes = {
  /**
   * Optional extra class to assign to the <li> element
   */
  className: PropTypes.string,

  /**
   * A customised element to render inside the button, in place of the standard label.
   *
   * NOTE: for accessibility, even when using a customLabelElement, you **MUST** still provide a value for `label` that describes what this button does!
   */
  customLabelElement: PropTypes.node,

  /**
   * Whether the button is in a loading state
   */
  isLoading: PropTypes.bool,

  /**
   * Label to show on the nav item
   */
  label: PropTypes.string.isRequired,

  /**
   * Function to call when the button is clicked
   */
  onClick: PropTypes.func,
};

export default NavBarButtonItem;
