import PropTypes from 'prop-types';
import { useState } from 'react';

import Drawer from '../Drawer';

export default function LazyDrawer({
  children,
  lazyComponent,
  renderTrigger,
  ...drawerProps
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [Component, setComponent] = useState(null);

  async function loadComponent() {
    const LoadedComponent = await lazyComponent();

    setComponent(() => LoadedComponent);
  }

  return (
    <Drawer
      {...drawerProps}
      renderTrigger={({ openDrawer }) => {
        return renderTrigger({
          isLoading,
          openDrawer: async () => {
            setIsLoading(true);

            await loadComponent();

            openDrawer();

            setIsLoading(false);
          },
        });
      }}
    >
      {Component ? children(Component) : null}
    </Drawer>
  );
}

LazyDrawer.propTypes = {
  /**
   * A function which should return a component to be rendered in the drawer.
   */
  children: PropTypes.func.isRequired,

  /**
   * A function which should return a promise that resolves to the component to be loaded
   */
  lazyComponent: PropTypes.func.isRequired,

  /**
   * A function which should handle opening the content of the drawer.
   *
   * We pass back an object with the following properties:
   * - isLoading: A boolean indicating if the component is currently loading.
   * - openDrawer: A function which should be called to open the drawer.
   */
  renderTrigger: PropTypes.func.isRequired,
};
