import { default as NextLink } from 'next/link';
import React from 'react';

import analytics from 'site-react/helpers/Analytics';
import useMembership from 'site-react/hooks/useMembership';
import useUser from 'site-react/hooks/useUser';

import styles from './FreeTrialBanner.module.css';

const FreeTrialBanner = () => {
  const { user } = useUser();
  const { plan } = useMembership();

  if (!plan || !plan.abilities.includes('free-trial-banner')) {
    return null;
  }

  let contentText;
  if (plan.status === 'expired') {
    contentText = 'Your free trial has expired.';
  } else if (plan.freeTrialDaysRemaining === 0) {
    contentText = 'Free trial ends today.';
  } else if (plan.freeTrialDaysRemaining === 1) {
    contentText = 'Free trial ends in 1 day.';
  } else {
    contentText = `Free trial ends in ${plan.freeTrialDaysRemaining} days`;
  }

  if (user?.role === 'admin') {
    return (
      <NextLink
        href="/dashboard/pass"
        onClick={() => analytics.track('Upgrade Free Trial Banner Clicked')}
      >
        <span className={styles['FreeTrialBanner-wrapper']}>
          <b>{contentText} </b>
          <span className={styles['FreeTrialBanner-fakeLink']}>
            Upgrade now
          </span>
        </span>
      </NextLink>
    );
  } else {
    return (
      <span className={styles['FreeTrialBanner-wrapper']}>
        <b>{contentText}</b>
      </span>
    );
  }
};

export default FreeTrialBanner;
