import cn from 'classnames';
import Image from 'next/image';
import PropTypes from 'prop-types';
import React from 'react';

import config from 'site-react/config';
import getAppUrl from 'site-react/helpers/getAppUrl';
import getImgixPathWithParameters from 'site-react/helpers/getImgixPathWithParameters';
import imgixLoader from 'site-react/helpers/imgixLoader';
import theme from 'site-react/theme';

import styles from './ImageCarouselItemCard.module.css';

const ImageCarouselItemCard = ({
  alt = '',
  loadable,
  prefetch = false,
  selected = false,
  src,
}) => {
  const srcUrl = new URL(src, getAppUrl(config));

  return (
    <li
      className={cn(styles['ImageCarouselItemCard-wrapper'], {
        [styles['ImageCarouselItemCard-wrapperStyleSelected']]: selected,
      })}
    >
      <Image
        alt={alt}
        fill
        loader={imgixLoader}
        sizes={
          /* At mobile listing cards fill the screen */
          `(max-width: ${theme.breakpoints.sm}px) 100vw, ` +
          /* At desktop, listing cards will never be much wider than 392px */
          '392px'
        }
        src={getImgixPathWithParameters(srcUrl.pathname, {
          ar: '4:3',
          fit: 'crop',
          q: 30,
        })}
        style={{
          objectFit: 'cover',
        }}
      />
    </li>
  );
};

ImageCarouselItemCard.propTypes = {
  /**
   * The alt text for this image
   */
  alt: PropTypes.string,

  loadable: PropTypes.bool,

  /**
   * Should we start loading this image, so it's ready when it gets displayed?
   *
   * NOTE: This will get set automatically by ImageCarousel. Do not specify
   * it in your props.
   * NOTE: this is actually used, but only in getDerivedStateFromProps. eslint
   * throws an inaccurate warning.
   */
  prefetch: PropTypes.bool,

  /**
   * Is this the currently-visible item?
   *
   * NOTE: This will get set automatically by ImageCarousel. Do not specify
   * it in your props.
   */
  selected: PropTypes.bool,

  /**
   * URL of the image to show. Expected to be a parameterless imgix URL.
   */
  src: PropTypes.string.isRequired,
};

export default ImageCarouselItemCard;
