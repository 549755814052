import cn from 'classnames';
import React from 'react';

import { MaterialIcon } from 'site-react/components/typography';

import styles from './PassBadge.module.css';

const PassBadge = () => {
  return (
    <span className={cn('.u-typographyLabel1', styles.PassBadge)}>
      <MaterialIcon
        className={styles['PassBadge-icon']}
        iconType="offline_bolt"
      />
      On-demand
    </span>
  );
};

export default PassBadge;
