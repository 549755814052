import cn from 'classnames';
import React, { useEffect, useState, Suspense } from 'react';

import { Button } from 'site-react/components/form';
import { CloseButton } from 'site-react/components/navigation';
import { Heading, Paragraph } from 'site-react/components/typography';
import { Spinner, VerticalSpacing } from 'site-react/components/utility';
import useCookiePreferences from 'site-react/hooks/useCookiePreference';
import theme from 'site-react/theme';

import styles from './CookieBanner.module.css';

const CookieManagePreferencesForm = React.lazy(
  () => import('site-react/features/CookieManagePreferences'),
);

const CookieBannerDefaultScreen = ({ setScreen }) => {
  const [widthOfViewport] = useState(window.innerWidth);
  const isMobile = widthOfViewport < theme.breakpoints.md;
  const { acceptAll } = useCookiePreferences();

  return (
    <div className={styles['CookieBanner-defaultScreen']}>
      <div className={styles['CookieBanner-highlightedText']}>
        <Heading type="hero2">We’d like to give you some cookies</Heading>
        <VerticalSpacing size="sm" />
        <Paragraph type="content2">
          Not the biscuity kind, many of our workspaces give you those for free.
          The ones we have are used to enhance your experience and for marketing
          and analytics purposes. You can learn more in our{' '}
          <a href="/privacy-policy" target="_blank">
            Privacy & Cookies Policy
          </a>
          .
        </Paragraph>
      </div>
      <VerticalSpacing size="xxl" />
      <div className={styles['CookieBanner-actionsWrapper']}>
        <button
          className={styles['CookieBanner-link']}
          onClick={() => setScreen('preferences')}
          type="button"
        >
          Cookie Settings
        </button>
        <div className={styles['CookieBanner-buttonWrapper']}>
          <Button name="Accept Cookies" onClick={acceptAll} type="button">
            {isMobile ? 'Accept' : 'Accept cookies'}
          </Button>
        </div>
      </div>
    </div>
  );
};

const CookieBannerPreferencesScreen = ({ setScreen }) => {
  return (
    <div className={styles['CookieBanner-preferencesScreen']}>
      <div className={styles['CookieBanner-closePreferencesWrapper']}>
        <CloseButton color="white" onClick={() => setScreen('default')} />
      </div>
      <CookieManagePreferencesForm />
    </div>
  );
};

const CookieBanner = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => (document.body.style.overflow = 'scroll');
  });

  const [screen, setScreen] = useState('default');

  let content;
  if (screen === 'default') {
    content = <CookieBannerDefaultScreen setScreen={setScreen} />;
  } else if (screen === 'preferences') {
    content = <CookieBannerPreferencesScreen setScreen={setScreen} />;
  } else {
    throw new Error(`Unknown screen: ${screen}`);
  }

  return (
    <div
      className={cn(styles.CookieBanner, {
        [styles['CookieBanner--fullScreenAtMobile']]: screen === 'preferences',
      })}
    >
      <div className={styles['CookieBanner-banner']}>
        <Suspense
          fallback={
            <div className={styles['CookieBanner-loading']}>
              <Spinner />
            </div>
          }
        >
          {content}
        </Suspense>
      </div>
    </div>
  );
};

export default CookieBanner;
