import {
  citymapper,
  monzo,
  nhs,
  oddbox,
  pricewaterhouseCoopers,
  trustpilot,
} from 'site-react/assets';
import { Asset } from 'site-react/components/utility';

import styles from './LogoGrid.module.css';

const logos = [
  {
    alt: 'Pricewaterhouse Coopers',
    src: pricewaterhouseCoopers,
  },
  { alt: 'Citymapper', src: citymapper },
  { alt: 'Monzo', src: monzo },
  { alt: 'Trustpilot', src: trustpilot },
  { alt: 'NHS', src: nhs },
  { alt: 'Oddbox', src: oddbox },
];

const LogoGrid = () => {
  return (
    <ul className={styles['LogoGrid']}>
      {logos.map(({ alt, src }) => {
        return (
          <li className={styles['LogoGrid-logoItem']} key={alt}>
            <Asset alt={alt} sizingBehaviour="fillToNatural" src={src} />
          </li>
        );
      })}
    </ul>
  );
};

export default LogoGrid;
