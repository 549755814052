import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { Modal } from 'site-react/components/page';
import analytics from 'site-react/helpers/Analytics';
import { GlobalEvents, keyEventsHandler } from 'site-react/helpers/Events';

import styles from './ImageCarousel.module.css';
import ImageCarouselExpandButton from './ImageCarouselExpandButton';
import ImageCarouselMoveButton from './ImageCarouselMoveButton';
import ImageCarouselPosition from './ImageCarouselPosition';

/*
 * Throughout this component, accessibility features are implemented to
 * largely match https://www.w3.org/WAI/tutorials/carousels/functionality/
 */

const ImageCarousel = ({
  allowExpansion = false,
  bottomAccessories = null,
  children = null,
  compact = false,
  desktopRatio = '3:2',
  enableImageCarouselCaptions,
  fullScreen = false,
  isRounded = false,
  mobileRatio = '4:3',
  overrideAspectRatio = false,
  showButtonsOnHover = false,
  showPosition = true,
  smallArrowButtons = false,
  startPosition = 0,
  topAccessories = null,
  trackingId,
  trackingLocation = 'carousel',
}) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(startPosition);

  const handleNext = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const totalItems = React.Children.count(children);

    if (currentItemIndex < totalItems - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
    }

    if (currentItemIndex === totalItems - 1) {
      setCurrentItemIndex(0);
    }

    analytics.track('Carousel Next Clicked', {
      content_ids: [trackingId],
      currentIndex: currentItemIndex,
      location: trackingLocation,
      nextIndex: currentItemIndex + 1,
    });
  };

  const handlePrevious = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const totalItems = React.Children.count(children);

    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    }

    if (currentItemIndex === 0) {
      setCurrentItemIndex(totalItems - 1);
    }

    analytics.track('Carousel Previous Clicked', {
      content_ids: [trackingId],
      currentIndex: currentItemIndex,
      location: trackingLocation,
      nextIndex: currentItemIndex - 1,
    });
  };

  const globalEvents = new GlobalEvents({
    keyup: {
      debounce: false,
      handle: keyEventsHandler({
        ArrowLeft: handlePrevious,
        ArrowRight: handleNext,
      }),
      useCapture: true,
    },
  });

  const getCarouselItems = (children, selectedIndex, fullScreen) => {
    return React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          isFullScreen: fullScreen,
          prefetch: [selectedIndex + 1, selectedIndex + 2].includes(index),
          selected: selectedIndex === index,
          // Pre-load the next 2 images
        });
      }
    });
  };

  useEffect(() => {
    if (fullScreen) {
      globalEvents.listen();
    }
    return () => {
      globalEvents.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fullScreen) {
      globalEvents.listen();
    } else {
      globalEvents.remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreen]);

  const totalItems = React.Children.count(children);
  const singleItem = totalItems <= 1;

  const aspectRatio = {
    '2:1': { height: 1, width: 2 },
    '3:2': { height: 2, width: 3 },
    '4:3': { height: 3, width: 4 },
  };

  const carouselItems = getCarouselItems(
    children,
    currentItemIndex,
    fullScreen,
  );

  return (
    <div
      className={cn({
        [styles['ImageCarousel--fullScreen']]: fullScreen,
      })}
    >
      <div
        className={cn(styles['ImageCarousel-sizer'], {
          [styles['ImageCarousel--showButtonsOnHover']]: showButtonsOnHover,
          [styles['ImageCarousel-sizer--compact']]: compact,
          [styles['ImageCarousel--overrideAspectRatioStyleDesktop']]:
            overrideAspectRatio,
          [styles['ImageCarousel--overrideAspectRatioStyleMobile']]:
            overrideAspectRatio,
          [styles['ImageCarousel-sizer--fullScreen']]: fullScreen,
        })}
        style={{
          '--ImageCarousel-desktopHeight': aspectRatio[desktopRatio].height,
          '--ImageCarousel-desktopWidth': aspectRatio[desktopRatio].width,
          '--ImageCarousel-mobileHeight': aspectRatio[mobileRatio].height,
          '--ImageCarousel-mobileWidth': aspectRatio[mobileRatio].width,
        }}
      >
        <ul
          className={cn(styles['ImageCarousel-wrapper'], {
            [styles['ImageCarousel-wrapper--fullScreen']]: fullScreen,
            [styles['ImageCarousel-wrapper--rounded']]:
              !fullScreen && isRounded && !bottomAccessories,
            [styles['ImageCarousel-wrapper--roundedWithBottomAccessories']]:
              !fullScreen && isRounded && bottomAccessories,
          })}
        >
          {carouselItems}
        </ul>

        {showPosition && (
          <ImageCarouselPosition
            caption={carouselItems[currentItemIndex]?.props?.caption}
            current={currentItemIndex + 1}
            enableImageCarouselCaptions={enableImageCarouselCaptions ?? false}
            isFullScreen={fullScreen}
            max={totalItems}
          />
        )}
        {allowExpansion && (
          <Modal
            centerContent
            id="image-carousel-modal"
            isFullScreen
            modalName="Image Carousel"
            renderTrigger={({ openModal }) => (
              <ImageCarouselExpandButton
                analyticsMetadata={{
                  content_ids: [trackingId],
                  location: trackingLocation,
                }}
                label="Carousel Expand Button Clicked"
                onClick={openModal}
              />
            )}
          >
            <ImageCarousel
              allowExpansion={false}
              bottomAccessories={bottomAccessories}
              compact={compact}
              desktopRatio={desktopRatio}
              enableImageCarouselCaptions={enableImageCarouselCaptions}
              fullScreen
              isRounded={isRounded}
              mobileRatio={mobileRatio}
              overrideAspectRatio={overrideAspectRatio}
              showButtonsOnHover={showButtonsOnHover}
              showPosition={showPosition}
              smallArrowButtons={smallArrowButtons}
              startPosition={currentItemIndex}
              topAccessories={topAccessories}
              trackingId={trackingId}
              trackingLocation={trackingLocation}
            >
              {children}
            </ImageCarousel>
          </Modal>
        )}

        <ImageCarouselMoveButton
          direction="previous"
          disabled={singleItem}
          isExpandedView={fullScreen}
          onClick={handlePrevious}
          smallArrowButtons={smallArrowButtons}
        />
        <ImageCarouselMoveButton
          direction="next"
          disabled={singleItem}
          isExpandedView={fullScreen}
          onClick={handleNext}
          smallArrowButtons={smallArrowButtons}
        />
        {topAccessories && !fullScreen && (
          <div className={styles['ImageCarousel-topAccessories']}>
            {topAccessories}
          </div>
        )}
      </div>
      {bottomAccessories && !fullScreen && (
        <div
          className={cn(styles['ImageCarousel-bottomAccessories'], {
            [styles['ImageCarousel-bottomAccessories--rounded']]: isRounded,
          })}
        >
          {bottomAccessories}
        </div>
      )}
    </div>
  );
};

ImageCarousel.propTypes = {
  /**
   * Allow this carousel to expand into a full-screen modal?
   */
  allowExpansion: PropTypes.bool,

  /**
   * When set, runs a banner underneath the image carousel containing actions
   */
  bottomAccessories: PropTypes.node,

  /**
   * Items to show in the carousel. Should be `CarouselItem`s.
   *
   * Must be list items (<li />).
   */
  children: PropTypes.node,

  /**
   * Compact style variant
   */
  compact: PropTypes.bool,

  /**
   * Overridden aspect ratio value for desktop breakpoint.
   */
  desktopRatio: PropTypes.oneOf(['2:1', '3:2', '4:3']),

  /**
   * Whether to show captions for the images.
   */
  enableImageCarouselCaptions: PropTypes.bool,

  /**
   * Full-screen variant. Enabling full-screen activates arrow key navigation
   */
  fullScreen: PropTypes.bool,

  /**
   * Bool that applies rounded border-radius if true.
   */
  isRounded: PropTypes.bool,

  /**
   * Overridden aspect ratio value for mobile breakpoint.
   */
  mobileRatio: PropTypes.oneOf(['2:1', '3:2', '4:3']),

  /**
   * Bool that overrides default aspect ratio if true.
   */
  overrideAspectRatio: PropTypes.bool,

  /**
   * Wether to only show the arrow buttons on hover.
   */
  showButtonsOnHover: PropTypes.bool,

  /**
   * Wether to show the Carousel position.
   */
  showPosition: PropTypes.bool,

  /**
   * Small arrow buttons variant
   */
  smallArrowButtons: PropTypes.bool,

  /**
   * The slide number to open with
   */
  startPosition: PropTypes.number,

  /**
   * When set, runs a banner along the top of the image carousel containing actions
   */
  topAccessories: PropTypes.node,

  /**
   * The ID that should be associated with this carousel. Used for analytics.
   *
   * Suggested value: the current listing or office space ID.
   */
  trackingId: PropTypes.number.isRequired,

  /**
   * The location that should be associated with this carousel.
   * Used for analytics.
   *
   * Suggested value: name of the current page.
   */
  trackingLocation: PropTypes.string,
};

export default ImageCarousel;
