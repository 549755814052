import { Paragraph } from 'site-react/components/typography';

import BookingButton from './BookingButton';
import styles from './PassBookingContent.module.css';

const PassBookingContent = ({
  building,
  capacityText,
  isBookingButtonEnabled,
  priceText,
  product,
}) => {
  return (
    <div className={styles['PassBookingContent-rowDetailsWrapper']}>
      <div className={styles['PassBookingContent-requestToBookTextWrapper']}>
        <Paragraph isMarginless type="content2">
          <b>{capacityText}</b>
        </Paragraph>
        <Paragraph type="content3">
          <b>{priceText}</b>
        </Paragraph>
      </div>
      <BookingButton
        building={building}
        buttonType={product.productType}
        isEnabled={isBookingButtonEnabled}
        product={product}
      />
    </div>
  );
};

export default PassBookingContent;
